<template>
	<div class="comments-container" :class="{noAccess : noAccess}">
		<CommentsList :key="this.reloadCommentlist"></CommentsList>
		<div class="comments-strip">
			<span class="comments-strip__count">
				{{ commentCount }}
			</span>
			<i class="fa-solid fa-message" @click.prevent="this.$store.commit('client/setOpenCommentPanel', !this.getOpenCommentPanel)"></i>
		</div>
	</div>
</template>

<script>
import CommentsList from '../lists/CommentsList.vue';
import {mapGetters} from "vuex"
export default {
    components: {
		CommentsList,
	},
    data() {
		return {
			reloadCommentlist: 0,
			noAccess: false,
        }
    },
    computed: {
		...mapGetters("client", ['getCurrentNode', 'getComments', 'getOpenCommentPanel']),
		commentCount(){
			return this.getComments.filter(comment => comment.resolved === false && comment.parentCommentID === '').length;
		},
	},
	mounted(){
		this.isAllowed();
	},
	watch:{
		'getCurrentNode':{
            async handler() {
				this.isAllowed();
            },
            deep: true
        },
	},
	methods:{
		isAllowed(){
			console.log('this.getCurrentNode.isImported', this.getCurrentNode.isImported);
			if(this.getCurrentNode.isImported){
				this.noAccess = true
				this.$store.commit('client/setOpenCommentPanel', false);
			}else{
				this.noAccess = false
			}
		}
	}
}
</script>


<style lang="scss">
@import "./../../../src/assets/scss/components/_comments.scss";
</style>