<template>

    <div class="keyword-planner">

        <div class="g-table__header button-header">
            <button class="btn-close media-modal__exit" @click.prevent='this.$store.commit("client/setOpenKeywordPlanner", false);'><i class="fa-sharp fa-solid fa-xmark"></i>Close</button>
        </div>


        <div class="g-table__header">
            <h1 class="keyword-planner__title">Keyword Planner</h1>
        </div>

        <p class="keyword-planner___helper-text">Search for a keyword</p>
        <div class="keyword-planner__search-container">
            <input placeholder="Search Keyword" type="text" name="keyword-search" id="keyword-search-input" v-model="inputVal">
            <button class="btn-primary btn-primary--outline" id="keyword-search-btn" @click.prevent="this.$store.dispatch('client/keywordPlanner', inputVal)"><i class="fa-solid fa-magnifying-glass"></i>Search</button>
        </div>

        <div class="keyword-planner__table"  v-if="getKeywordPlanner.tableData.length !== 0">
            <div class="g-table keyword-planner__table-header">
                  <table class="g-table__table table-header" cellspacing="0">
                      <thead>
                          <tr>
                              <th width="14.2%" scope="col">Keyword</th>
                              <th width="14.2%" scope="col">Monthly search vol.</th>
                              <th width="14.2%" scope="col">Competition</th>
                              <th width="14.2%" scope="col">High CPC</th>
                              <th width="14.2%" scope="col">Low CPC</th>
                              <th width="14.2%" scope="col"></th>
                          </tr>
                      </thead>
                  </table>
            </div>

            <div class="g-table keyword-planner__table-body">
                <table class="g-table__table" cellspacing="0">
                    <tbody>
                        <tr v-for="(row) in getKeywordPlanner.tableData" :key="row">
                            <th width="14.2%" scope="row"><div v-html="row[0]"></div></th>
                            <td width="14.2%"><div v-html="row[1]"></div></td>
                            <td width="14.2%"><div v-html="row[2]"></div></td>
                            <td width="14.2%"><div v-html="row[3]"></div></td>
                            <td width="14.2%"><div v-html="row[4]"></div></td>
                            <td class="td-btn" width="14.2%"><button @click.prevent="this.$store.commit('client/setKeywordData', {title: row[0], reach: row[1]})">Save as keyword <i class="fa-sharp fa-solid fa-chevron-right"></i></button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>

</template>

<script>

import {mapGetters} from "vuex"

export default {
    data(){
        return {
            inputVal: ''
        }
    },
    computed:{
        ...mapGetters("client", ["getClientSlug", "getKeywordPlanner"]),
    },
}
</script>

<style lang="scss">
@import "./../../assets/scss/components/_keyword-planner.scss";
</style>