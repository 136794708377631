<template>
    <div 
        v-if="getNotification.text"
        class="notification" 
        :class="{ 
            notification__valid : getNotification.type === 'valid', 
            notification__error : getNotification.type === 'error', 
        }"
        >
        <div>
            <h3>{{ getNotification.text }}</h3>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
export default {
    computed:{
        ...mapGetters("tools", ["getNotification"]),
    },
}
</script>

<style lang="scss" scoped>
@import "./../../assets/scss/components/_notification.scss";
</style>