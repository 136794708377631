<template>
    <div class="publish-date date-picker">
        <span>Auto Publish on:</span>
        <VueDatePicker 
        minutes-grid-increment="5" minutes-increment="5"
            :disabled="getSocial.post.hasbeenPublished" 
            :min-date="new Date()" 
            :max-date="new Date(Date.now() + 60 * 24 * 60 * 60 * 1000)" 
            format="dd/MM/yyyy hh:mm a" 
            v-model="publishDate">
        </VueDatePicker>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
    computed: {
        ...mapGetters("client", ["getClientSlug", 'getSocial']),

        publishDate: {
            get() {
                let setDate = this.getSocial.post.publishDate; //2024-07-03T11:00:00.000Z
                if (setDate) {
                    let date = new Date(setDate);

                    // Check if the date is in British Summer Time (BST)
                    const isBST = (date) => {
                        const jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
                        const jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
                        const dst = Math.max(jan, jul);
                        return date.getTimezoneOffset() < dst;
                    };

                    if (isBST(date)) {
                        // Go back one hour
                        date.setHours(date.getHours() - 1);
                    }

                    return date.toISOString();
                }
                return setDate;
            },
            set(value) {
                let setDate = value; //2024-07-03T11:00:00.000Z
                if (setDate) {
                    let date = new Date(setDate);

                    // Check if the date is in British Summer Time (BST)
                    const isBST = (date) => {
                        const jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
                        const jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
                        const dst = Math.max(jan, jul);
                        return date.getTimezoneOffset() < dst;
                    };

                    if (isBST(date)) {
                        // Go back one hour
                        date.setHours(date.getHours() + 1);
                    }

                    this.$store.commit('client/setSocial', { prop: 'post/publishDate', val: date.toString() });
                }
            }
        },
    },
    components: { VueDatePicker }
};
</script>

<style lang="scss">
@import "./../../../assets/scss/components/_social.scss";
</style>
