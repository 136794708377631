<template>
    <div v-if="this.getMutliSelectNodeCategories.modalIsOpen" class="ai-pop-up-wrap">
        <div class="ai-generate-fields" v-if="loader === false">
            <label for="voice">Filter Categories</label>
            <div v-if="this.getMutliSelectNodeCategories.allUsers && this.getMutliSelectNodeCategories.allUsers.length > 0">
                <multiselect v-model="value" tag-placeholder="Add this as new category" placeholder="Search or add a category" label="name" track-by="code" :options="options" :multiple="true" :taggable="true" @tag="addTag"></multiselect>
            </div>
            <div v-else>
                <p>Waiting for categories.</p>
            </div>
            <div class="btn-wrap">
              <button class="btn-save" @click.prevent="onSubmit()"><i class="fa-solid fa-plus"></i>Add</button>
              <button class="btn-close" @click.prevent="onClose()"><i class="fa-sharp fa-solid fa-xmark"></i>Close</button>
            </div>
        </div>
        <div v-if="loader">
            <h2 style="color: white;">Loading...</h2>
        </div>
    </div>
</template>


<script>
import { mapGetters } from 'vuex';
import multiselect from 'vue-multiselect';

export default {
  data(){
    return {
      open: false,
      loader: false,
      value: [],
      options: [],
      contentTreeHasContent: false,
      importedTreeHasContent: false
    }
  },
  components: { multiselect },
  computed: {
    ...mapGetters('client', ['getSocial', 'getMutliSelectNodeCategories', 'getClientContentTree', 'getImportedContentTree']),
  },
  watch:{
    'getClientContentTree.nodes':{
        handler(nodes) {
            nodes.length > 0 ? this.contentTreeHasContent = true : this.contentTreeHasContent = false;
            if(this.contentTreeHasContent && this.importedTreeHasContent){
                this.init();
            }
        },
        deep: true
    },
    'getImportedContentTree.nodes':{
        handler(nodes) {
            nodes.length > 0 ? this.importedTreeHasContent = true : this.importedTreeHasContent = false;
            if(this.contentTreeHasContent && this.importedTreeHasContent){
                this.init();
            }
        },
        deep: true
    },
  },
  methods: {
    init(){
        const formatedImportedNodes = this.getImportedContentTree.nodes.map(node => ({name: node.nodesData.category.title, code: node.nodesData.category.id}));
        const formatedNodes = this.getClientContentTree.nodes.filter(node => node.category === 'self').map(node => ({name: node.title, code: node.id}));
        const allNodes = [...formatedImportedNodes, ...formatedNodes];

        console.log('init : allNodes', allNodes);

        this.setOptions(allNodes);
        this.setValue(this.options);

        this.$store.commit('client/setMutliSelectNodeCategories', {prop: 'allUsers', val: allNodes});
    },
    addTag (newTag) {
      const tag = {
        name: newTag,
        code: newTag
      }
      this.options.push(tag)
      this.value.push(tag)
    },
    setOptions(data){
        this.options = data;
    },
    setValue(data){
        this.value = data;
    },
    onClose(){
        this.$store.commit('client/setMutliSelectNodeCategories', {prop: 'modalIsOpen', val: false});
        this.$store.commit('client/setMutliSelectNodeCategories', {prop: 'selected', val: []})
        this.$store.commit('client/setMutliSelectNodeCategories', {prop: 'filteredCategoryNodes', val: ''})
        this.$store.commit('client/setMutliSelectNodeCategories', {prop: 'lastComponentClicked', val: false})
    },
    onSubmit(){
        if(this.value && this.value.length > 0){
            this.$store.commit('client/setMutliSelectNodeCategories', {prop: 'filteredCategoryNodes', val: this.value});
            this.$store.commit('client/setMutliSelectNodeCategories', {prop: 'modalIsOpen', val: false});
            console.log('this.getMutliSelectNodeCategories.filteredCategoryNodes', this.getMutliSelectNodeCategories.filteredCategoryNodes);
        }else{
            alert('You must select a category');
        }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
@import './../../assets/scss/components/_social.scss';
.multiselect__tags{
    background: #3D4163;
    border: 1px solid #3D4163;
    margin: 20px 0;
}
.multiselect__input{
    background: #3D4163;
    border: 1px solid #3D4163 !important;
    color: white;
}
.multiselect__placeholder{
    color: white;
    font-size: 13px;
}
.multiselect__content-wrapper{
    background: #3D4163;
    border: 1px solid #3D4163;
}
.multiselect__element{
    margin-block: 10px !important;
}
.multiselect__element span{
    font-size: 13px;
    color: white;
}
.multiselect__tag-icon,
.multiselect__tag-icon:hover{
    color: white;
    background: rgb(58, 179, 220);
}
.multiselect__tag, 
.multiselect__option--highlight, 
.multiselect__option--highlight::after, 
.multiselect__option--selected,
.multiselect__option--selected::after{
    background: rgb(58, 179, 220);
    color: white;
};
</style>