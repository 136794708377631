<template>
    <div class="save-post">
        <button class="btn-primary btn-primary--outline media-social-btn"  style="width: 140px; max-width: unset; min-width: unset;" @click.prevent="onOpenMediaModal" v-if="getSocial.post.hasbeenPublished === false && getSocial.post.media.length < 10"><i class="fa-solid fa-upload"></i>Add Media</button>
        <button class="btn-save" style="margin-left: auto; width: 270px; max-width: unset;" @click.prevent="schedulePost"  v-if="allowedToPost === true && this.getSocial.post.hasbeenPublished === false"><i class="fa-solid fa-clock"></i>Schedule Post</button>
        <button class="btn-save" style="margin-left: auto; width: 270px; max-width: unset;" @click.prevent="schedulePost"  v-if="allowedToPost === false && this.getSocial.post.hasbeenPublished === false"><i class="fa-solid fa-clock"></i>Submit for Approval</button>
    </div>
</template>

<script>

import {mapGetters} from "vuex"

export default {
    
    computed:{
        ...mapGetters("auth", ["getAuthData", "getAuthRoleName"]),
        ...mapGetters("client", ["getClientSlug", 'getSocial', 'getCurrentNode', 'getClientData']),
        allowedToPost(){
            // if(this.getAuthRoleName === 'Super Admin'){
            // return true;
            // }else if(this.getClientData.mainContact.username === this.getAuthData.username){
            //     return true;
            // }else{
            //     return false;
            // }
            if(this.getCurrentNode.fieldGroups.status.fields.status.value === 'Approved'){
                return true;
            }else{
                if(this.getClientData.mainContact && this.getClientData.mainContact.username === this.getAuthData.username){
                    return true;
                }else{
                    return false;
                }
            }
        }
    },
    methods: {
        async schedulePost(){
            this.$store.commit('client/setHidePageLoader', false);
            try{
                console.log('context222', this.getSocial.post.scheduleTo);
                const isNodePosting = await this.$store.dispatch("client/isNodePosting");

                if(isNodePosting.status === false){
                    let hasErrors = false;
                    if( this.getCurrentNode.fieldGroups.format.fields.focus.value === 'Facebook' 
                        && this.getSocial.socialAccountInformation.meta.fbPageTokenStatus.is_valid == false
                        && this.getSocial.socialAccountInformation.meta.fbUserTokenStatus.is_valid == false
                    ){
                        alert('You must authenticate Facebook');
                        hasErrors = true;
                    }
                    if( this.getCurrentNode.fieldGroups.format.fields.focus.value === 'Instagram' 
                        && this.getSocial.socialAccountInformation.meta.fbPageTokenStatus.is_valid == false
                        && this.getSocial.socialAccountInformation.meta.fbUserTokenStatus.is_valid == false
                    ){
                        alert('You must authenticate Instagram');
                        hasErrors = true;
                    }
                    if( this.getCurrentNode.fieldGroups.format.fields.focus.value === 'X' 
                        && this.getSocial.socialAccountInformation.twitter.error === false
                    ){
                        alert('You must authenticate X');
                        hasErrors = true;
                    }
                    if( this.getCurrentNode.fieldGroups.format.fields.focus.value === 'Linkedin' 
                        && this.getSocial.socialAccountInformation.linkedin.name == false
                        && this.getSocial.socialAccountInformation.linkedin.pageName == false
                    ){
                        alert('You must authenticate Linkedin');
                        hasErrors = true;
                    }

                    const hasVideo = this.getSocial.post.media.some(element => element.endsWith('.mp4'));

                    if(hasVideo && this.getSocial.post.media.length > 1){
                        alert('Images cannot be mixed with videos');
                        hasErrors = true;
                        //You can post images and videos on instagram but you cant schedule images and videos carousel mix. This was tested in meta suite. 
                    }
                    if(this.getSocial.post.media.length === 0 && this.getSocial.post.scheduleTo.instagram){
                        hasErrors = true;
                        alert('Instagram requires media items');
                    }

                    if(hasErrors === false){

                        if(this.allowedToPost){
                            //this will add a flag in the db and the post will be published by the cron job
                            this.$store.commit('client/setSocial', {prop: 'post/approvedForPublishing', val: true});
                            await this.$store.dispatch('client/updateSocialPost');
                        }else{

                            this.$store.commit('client/setSocial', {prop: 'post/approvedForPublishing', val: false});
                            await this.$store.dispatch('client/updateSocialPost');
                            await this.$store.dispatch('client/notifyMainContactForApproval');
                        }
                        
                        this.$store.commit('client/setContentTreeWasResized', true);

                    }

                }else{
                    alert(isNodePosting.error)
                }
                this.$store.commit('client/setHidePageLoader', true);
                //reload the content tree
            }catch(error){
                console.log('error:', error);
            }

        },
        onOpenMediaModal(){
            this.$store.commit("tools/setIsOpenMediaModal", true);
            this.$store.commit("tools/setMediaModalCurrentComponent", 'SocialMedia');
        },
    }

}

</script>


<style lang="scss">

@import "./../../../assets/scss/components/_social.scss";

</style>

