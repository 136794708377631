<template>
    <div v-if="getAIPopUpFields.modals.node" class="ai-pop-up-wrap">
        <div class="ai-generate-fields">
            <label for="voice">Get inspiration from the parent node?</label>
            <input type="checkbox" name="get-parent" id="get-parent" :value="getAIPopUpFields.getParentData" @input="this.$store.commit('client/setAIPopUpFields', {prop: 'getParentData', val: $event.target.checked})">
            <label for="voice">Tone of voice</label>
            <select name="voice" id="" :value="getAIPopUpFields.voice" @input="this.$store.commit('client/setAIPopUpFields', {prop: 'voice', val: $event.target.value})">
                <option value="Expert">Expert</option>
                <option value="Daring">Daring</option>
                <option value="Playful">Playful</option>
                <option value="Sophisticated">Sophisticated</option>
                <option value="Persuasive">Persuasive</option>
                <option value="Supportive">Supportive</option>
            </select>
            <label for="special">Instructions</label>
            <textarea :placeholder="'e.g Create a social post that showcases my products features.'" name="instructions" id="" cols="30" rows="3" :value="getAIPopUpFields.instructions" @input="this.$store.commit('client/setAIPopUpFields', {prop: 'instructions', val: $event.target.value})"></textarea>
            <div class="btn-wrap">
              <button class="btn-save" @click.prevent="onSubmit()"><i class="fa-solid fa-wand-magic-sparkles"></i>Generate</button>
              <button class="btn-close" @click.prevent="this.$store.commit('client/setAIPopUpFields', {prop: 'modals/node', val: false})"><i class="fas fa-times"></i>Close</button>
            </div>
        </div>
    </div>
</template>


<script>
import { mapGetters } from 'vuex';

export default {
  data(){
    return {
      open: false,
    }
  },
  computed: {
    ...mapGetters('client', ['getAIPopUpFields', 'getCurrentNode']),
  },
  watch:{
        'getAIPopUpFields.format': {
        deep: true,
          async handler() {
              console.log('AI POP IS OPEN');
              this.fillInContent();
            }
        }
  },
  methods: {
    async onSubmit(){
      try{
        if(this.getAIPopUpFields.format === 'blog'){
          this.$store.commit('client/setAIPopUpFields', {prop: 'response', val: 'Generating content...'});
          this.$store.commit('client/setAIPopUpFields', {prop: 'format', val: ''})
          await this.$store.dispatch('client/AIGenerateText', 'blog');
        }else{
          this.$store.commit('client/setSocial', {prop: 'post/caption', val: 'Generating content...'});
          this.$store.commit('client/setAIPopUpFields', {prop: 'format', val: ''})
          await this.$store.dispatch('client/AIGenerateText', 'social');
        }
      }catch(error){
        console.log('onSubmit ai pop up error', error);
      }
    },
    fillInContent(){
      this.$store.commit('client/setAIPopUpFields', {prop: 'keywords', val: `${this.getCurrentNode.fieldGroups.keyword.fields.keyword.value}`});
      this.$store.commit('client/setAIPopUpFields', {prop: 'details', val: `${this.getCurrentNode.fieldGroups.details.fields.details.value}`});
      this.$store.commit('client/setAIPopUpFields', {prop: 'intent', val: `${this.getCurrentNode.fieldGroups.intent.fields.intent.value}`});
    }
  },
  mounted(){
    this.fillInContent();
  }
};
</script>

<style>
@import './../../assets/scss/components/_social.scss';
</style>



