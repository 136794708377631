<template>
    <div>
        <div class="bk-cover-info-modal"></div>
        <div class="info-modal" :class="{'info-modal__open' : this.getInfoModal.isOpen, 'sidebar-open-padding-left' : getSidebarOpenState }">
            <div class="info-modal__content" v-if="this.getInfoModal.isOpen === true">
                <p>{{ modalSteps ? modalSteps[this.getInfoModal.currentStep].text : 'loading' }}</p>
                <iframe v-if="modalSteps[this.getInfoModal.currentStep].video" :src="modalSteps[this.getInfoModal.currentStep].video"></iframe>
            </div>
                <div class="info-modal__preview-content" @click.prevent='this.$store.commit("tools/setIsOpenInfoModal", true)' v-if="this.getInfoModal.isOpen === false">
                <i class="fa-solid fa-question"></i>
                <p>Need Help?</p>
            </div>
            <div class="info-modal__content-footer"  v-if="this.getInfoModal.isOpen === true">
                <div class="info-modal__preview-content" @click.prevent='onClose()'>
                    <i class="fa-solid fa-circle-xmark"></i>
                    <p>Close</p>
                </div>
                <div>
                    <i class="fa-solid fa-circle-arrow-left" @click.prevent='this.$store.commit("tools/setCurrentStepInfoModal", backBtn)'></i>
                    <i class="fa-solid fa-circle-arrow-right" @click.prevent='this.$store.commit("tools/setCurrentStepInfoModal", nextBtn)'></i>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters} from "vuex"
export default {
    computed:{
        modalSteps(){
            return this.getInfoModal.steps[this.pageWereOn];
        },
        nextBtn(){
            if(document.querySelectorAll('.guide-active').length > 0){
                document.querySelectorAll('.guide-active').forEach((el) => {
                    el.classList.remove('guide-active');
                });
            }
            let val = this.getInfoModal.currentStep;
            if((this.modalSteps.length - 1) !== this.getInfoModal.currentStep){
                val = this.getInfoModal.currentStep + 1; 
            }
            this.updateClasses(val);
            return val;
        },
        backBtn(){
            if(document.querySelectorAll('.guide-active').length > 0){
                document.querySelectorAll('.guide-active').forEach((el) => {
                    el.classList.remove('guide-active');
                });
            }
            let val = this.getInfoModal.currentStep;
            if(this.getInfoModal.currentStep !== 0){
                val = this.getInfoModal.currentStep - 1; 
            }
            this.updateClasses(val);
            return val;
        },
        ...mapGetters("views", ["getSidebarOpenState"]),
        ...mapGetters("tools", ["getInfoModal"]),
    },
    methods:{
        updateClasses(val){
            if(document.querySelectorAll('.guide-active').length > 0){
                document.querySelectorAll('.guide-active').forEach((el) => {
                    el.classList.remove('guide-active');
                });
            }
            if(document.querySelectorAll(this.modalSteps[val].selector).length > 0){
                document.querySelectorAll(this.modalSteps[val].selector).forEach((el) => {
                    el.classList.remove('blur');
                    el.classList.add('guide-active');
                });
            }

            if(document.querySelectorAll('.blur').length > 0){
                document.querySelectorAll('.blur').forEach((el) => {
                    el.classList.remove('blur');
                });
            }
            if(this.modalSteps[val].blur.length > 0){
                this.modalSteps[val].blur.forEach((el) => {
                    if(document.querySelectorAll(el).length > 0){
                            document.querySelectorAll(el).forEach((el) => {
                                el.classList.add('blur');
                            });
                        }
                });
            }
        },
        updatePageWereOn() {
            let path = this.$route.path.substring(1);
            let params = new URLSearchParams(window.location.search);
            let list = params.get('list');
            if(path.includes('dashboard')) {
                this.pageWereOn = 'dashboard';
            } else if(path.includes('content') && list === 'true') {
                this.pageWereOn = 'contentListView';
            } else if(path.includes('content') && list === 'false'){
                this.pageWereOn = 'contentTreeView';
            }
        },
        onClose(){
            this.$store.commit("tools/setIsOpenInfoModal", false)
        },
        setActiveClass(){
            if(this.modalSteps && this.modalSteps[this.getInfoModal.currentStep] && document.querySelector(this.modalSteps[this.getInfoModal.currentStep].selector)){
                if(document.querySelectorAll(this.modalSteps[this.getInfoModal.currentStep].selector).length > 0){
                    document.querySelectorAll(this.modalSteps[this.getInfoModal.currentStep].selector).forEach((el) => {
                        el.classList.remove('blur');
                        el.classList.add('guide-active');
                    });
                }
                console.log('step-2-set-active-class');
                if(this.modalSteps[this.getInfoModal.currentStep].blur.length > 0){
                    this.modalSteps[this.getInfoModal.currentStep].blur.forEach((el) => {
                        if(document.querySelectorAll(el).length > 0){
                            document.querySelectorAll(el).forEach((el) => {
                                el.classList.add('blur');
                            });
                        }
                    });
                }
            }
        } 
    },
    data() {
        return {
            pageWereOn: '',
        }
    },
    mounted(){
        const steps = {
			dashboard : [
					{id: 0, selector: '.block.content', text: 'content tree block', blur: ['.sidebar', '.main-content', '.block', '.sidebar .close']},
					{id: 1, selector: '.block.social.purple', text: 'paid social block',  blur: ['.sidebar', '.main-content', '.block', '.sidebar .close']},
					{id: 2, selector: '.block.social.orange', text: 'organic social block',  blur: ['.sidebar', '.main-content', '.block', '.sidebar .close']},
					{id: 3, selector: '.block.social.blue', text: 'analaytics block',  blur: ['.sidebar', '.main-content', '.block', '.sidebar .close']},
					{id: 4, selector: '.block.seo', text: 'seo block',  blur: ['.sidebar', '.main-content', '.block', '.sidebar .close']},
					{id: 5, selector: '.block.ppc', text: 'ppc block',  blur: ['.sidebar', '.main-content', '.block', '.sidebar .close']},
					{id: 5, selector: '.sidebar .action.integrations', text: 'Authentication area',  blur: ['.sidebar', '.main-content', '.block', '.sidebar .close']},
                    {id: 6, selector: '.sidebar .action.logout', text: 'To logout',  blur: ['.sidebar', '.main-content', '.sidebar .close', '.range', '.zoom-info']},
				],
			contentTreeView : [
					{id: 0, selector: '.flex-container', text: 'This is the content tree, it creates a visual representation of your website structure. Click on a node to view and edit its content.', video: 'https://player.vimeo.com/video/347119375?h=1699409fe2&color=ef2200&byline=0&portrait=0',  blur: ['.sidebar','.sidebar .close']},
                    {id: 6, selector: '.list-view', text: 'To list view',  blur: ['.sidebar', '.main-content', '.sidebar .close', '.range', '.zoom-info']},
					{id: 1, selector: '.sidebar .close', text: 'Expand the side navigation to use more advanced features such as content filtering and search', blur: ['.sidebar', '.main-content', '.range', '.zoom-info', '.list-view']},
					{id: 2, selector: '.sidebar .filters-tree', text: 'This filters the tree',  blur: ['.sidebar', '.main-content', '.sidebar .close', '.range', '.zoom-info', '.list-view']},
                    {id: 3, selector: '.sidebar .legend', text: 'This is the legend',  blur: ['.sidebar', '.main-content', '.sidebar .close',  '.range', '.zoom-info', '.list-view']},
                    {id: 4, selector: '.sidebar .action.dashboard', text: 'to go dashboard',  blur: ['.sidebar', '.main-content', '.sidebar .close', '.range', '.zoom-info', '.list-view']},
                    {id: 5, selector: '.sidebar .action.integrations', text: 'to import nodes',  blur: ['.sidebar', '.main-content', '.sidebar .close', '.range', '.zoom-info', '.list-view']},
                    {id: 6, selector: '.sidebar .action.logout', text: 'To logout',  blur: ['.sidebar', '.main-content', '.sidebar .close', '.range', '.zoom-info', '.list-view']},
				],
			contentListView : [
					{id: 0, selector: '.main-content', text: 'This is the content tree as a list, it creates a visual representation of your website structure. You can unlock the content list by clicking the lock icon in the bottom left of the screen. Once unlocked you can drag and drop nodes to reorganize your content. Click on a node to view and edit its content.', video: 'https://player.vimeo.com/video/347119375?h=1699409fe2&color=ef2200&byline=0&portrait=0', blur: ['.sidebar', '.main-content']},
					{id: 1, selector: '.hover-icon2', text: 'You can drag these categories', blur: ['.sidebar', '.main-content', '.content-list-lock-btn', '.content-list-filter', '.btn-list-view'], moreThanOne: true},
					{id: 2, selector: '.btn-list-view', text: 'Go back to the content tree', blur: ['.sidebar', '.main-content', '.content-list-lock-btn', '.content-list-filter', '.btn-list-view']},
					{id: 3, selector: '.content-list-filter', text: 'filter what categories to show', blur: ['.sidebar', '.main-content', '.content-list-lock-btn', '.content-list-filter', '.btn-list-view']},
					{id: 4, selector: '.content-list-lock-btn', text: 'The content list lock icon. unlock it to make changes to the list that save to the content tree', blur: ['.sidebar', '.main-content', '.content-list-lock-btn', '.content-list-filter', '.btn-list-view']},
                    {id: 5, selector: '.sidebar .action.dashboard', text: 'to go dashboard',  blur: ['.sidebar', '.main-content', '.content-list-lock-btn', '.content-list-filter', '.btn-list-view']},
                    {id: 6, selector: '.sidebar .action.integrations', text: 'to import nodes',  blur: ['.sidebar', '.main-content', '.content-list-lock-btn', '.content-list-filter', '.btn-list-view']},
                    {id: 7, selector: '.sidebar .action.logout', text: 'To logout',  blur: ['.sidebar', '.main-content', '.content-list-lock-btn', '.content-list-filter', '.btn-list-view']},
				]
		};
		this.$store.commit("tools/setStepsInfoModal", steps);
    },
    watch:{
        'getInfoModal.isOpen': function(val){
            this.$store.commit("tools/setCurrentStepInfoModal", 0)
            if(val){
                this.$store.commit('client/setLockContentTree', true)
                this.updatePageWereOn();
                this.setActiveClass();
            }else{
                if(document.querySelectorAll('.blur').length > 0){
                    document.querySelectorAll('.blur').forEach((el) => {
                        el.classList.remove('blur');
                    });
                }
                if(document.querySelectorAll('.guide-active').length > 0){
                    document.querySelectorAll('.guide-active').forEach((el) => {
                        el.classList.remove('guide-active');
                    });
                }
                document.querySelector('.bk-cover-info-modal').classList.remove('active');
            }
        },
        pageWereOn: function(val){
            console.log('pageWereOn', val);
            if(val === 'contentTreeView'){
                console.log('pagewere on is content tree so open side bar')
                this.$store.commit('views/openSidebar');
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import "./../../assets/scss/components/_infoModal.scss";
</style>
<style lang="scss">
.info-modal__open{
    z-index: 9999999999 !important;
}
.guide-active{
    border: 2px solid $lightblue !important;
    z-index: 9999999 !important;
    pointer-events: none;
    border-radius: 5px;
}
.sidebar-open{
    .main-content.guide-active{
        margin-left:300px !important;
    }
}
.sidebar-closed{
    .main-content.guide-active{
        margin-left: 80px !important;
    }
}
.list-view-open .main-content.guide-active{
 width: calc(100vw - 80px) !important;
}


.blur:not(.guide-active){
    // background: red !important;
    &::after{
        content: '';
        background: rgba(36, 39, 80, 0.56);
        width: 100%;
        height:100%;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
    }
    &.block.disabled{
        &::after{
            display: none;
        }
    }
}
.disabled.guide-active{
    opacity: 1 !important;
    &::after{
        display: none;
    }
}
.flex-container.guide-active{
    border: 2px solid transparent !important;
    &::after{
        content: '';
        display: block;
        width: calc(100vw - 302px);
        height: 100vh;
        border: 2px solid $lightblue;
        position: absolute;
        top: -2px;
        right: 0;
        bottom: 0;
        left: 300px;
        z-index: 9999999999;
        border-radius: 5px;
    }
}
.filters-tree.guide-active, 
.legend.guide-active{
    border: 2px solid transparent !important;
    position: relative;
    &::after{
        content: "";
        display: block;
        width: 116%;
        height: 104%;
        border: 2px solid $lightblue;
        position: absolute;
        top: -17px;
        right: 0px;
        bottom: 0px;
        left: -23px;
        z-index: 9999999999;
        border-radius: 5px;
    }
}
.legend.guide-active::after{
    height: 118%;
}
.list-view.guide-active{
    max-width: 116px !important;
}
.zoom-info.blur, .range.blur{
    position: relative;
}
</style>