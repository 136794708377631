<template>
    <div class="blog" :class="{'blog__comments-open' : getOpenCommentPanel, 'has-been-published' : getScheduledBlogPosts.hasbeenPublished}">
			<div class="blog-container" v-if="getIsCreatingNewNode === false">
				<div class="blog-content">
					<div class="buttons">
						<button v-if="getScheduledBlogPosts.hasbeenPublished === false && isImported === false" class="btn-link mv" @click.prevent="this.$store.commit('client/setSocial', {prop: 'modalIsOpen', val: true})" ><i class="fa-solid fa-plug"></i>Integrations</button>
						<div @click.prevent="this.$store.dispatch('client/downloadBlog');" class="btn-primary btn-primary--outline download-blog-btn" v-if="getOpenBlogEditorAddNodeForm === false">
							<i class="fa-solid fa-download"></i>
							<span>Download Blog</span>
						</div>
					</div>
					
					<div class="blog-sub-fields">
						
						<div class="blog-sub-fields__container-1">
							<h1 v-if="getOpenBlogEditorAddNodeForm === true">Content</h1>
							
							<div class="blog-heading-wrap">
								<div v-if="getOpenBlogEditorAddNodeForm === true">
									<label for="blog-title"><p>Title</p></label>
									<input  type="text" name="blog-title" id="blog-title" :disabled="true" :value="getCurrentNodeBlogContent.title" @input="this.$store.commit('client/setBlogTitle', $event.target.value)">
								</div>
								<h1 v-else>{{ getCurrentNodeBlogContent.title }}</h1>
							</div>
							<ImageUpload :key="this.reloadComponent"  :valueProp="fieldValueBlogContent('featuredImageURL')" @fieldUpdated="(value) => updateBlogValue($event,'featuredImageURL', value)"></ImageUpload>
							
						</div>
						<div class="blog-sub-fields__container-2" v-if="getOpenBlogEditorAddNodeForm && !isImported">
							<PublishButton />
						</div>
					</div>
					<Wyswig :key="this.reloadComponent"></Wyswig>
					<p class="hidden-text">a</p>
					<p class="hidden-text">a</p>
				</div>
			</div>
            <CommentSidebar v-if="getIsCreatingNewNode === false" />
		<div v-if="getIsCreatingNewNode" style="justify-content: center; align-items: center; height: 500px; display: flex; width: 100%;">
			<h2 style="text-align: center;">You must save the node before creating/editing Posts</h2>
		</div>
    </div>
  </template>
  <script>

import { ref } from 'vue';
import {mapGetters} from "vuex"
import Wyswig from "../forms/inputs/Wyswig.vue";
import ImageUpload from '../forms/inputs/ImageUpload.vue';
import CommentSidebar from '../lists/CommentSidebar.vue';
import PublishButton from './blog/PublishButtons.vue';
  export default {
    components: {
		Wyswig,
		ImageUpload,
		CommentSidebar,
		PublishButton
	},
    data() {
		return {
            reloadComponent: 0,
			reloadCommentlist: 0,
			reloadBlogContent: 0,
            fieldValue: null,
			dropdown: ref(null),
			usersArray: [],
			forceUpdate: 0,
			tabIndex: 0,
			nodeExsists: false
        }
    },
    computed: {
		isImported(){
			return this.getCurrentNode.isImported ? true : false;
		},
        fieldValueBlogContent(){
			return (fieldTitle) => {
				return this.$store.getters["client/getNodeBlogContentValue"](fieldTitle)
			}
		},
        hasCurrentNodeBlogContentBeenEdited: {
            get() {
				return this.$store.getters["client/getHasCurrentNodeBlogContentBeenEdited"] || ""
            },
		},
		hasCommentsBeenUpdated: {
            get() {
				return this.$store.getters["client/getHasCommentsBeenUpdated"] || ""
            },
		},
		cancelComment: {
            get() {
				return this.$store.getters["client/getCancelComment"] || ""
            },
		},
		...mapGetters("client", ["getClientSlug", "getIsCreatingNewNode", "getCurrentNode", "getClientUsers", "hasCurrentNodeBeenEdited", "getOpenCommentPanel", "getTaggedUser", "getOpenBlogEditorAddNodeForm", "getCurrentNodeBlogContent", "getScheduledBlogPosts"]),
		...mapGetters("auth", ["getAuthUsername"]),
    },
    methods:{
        updateFieldValue($event, fieldGroup, field, payload) {
			let value = $event ? $event.target.value : payload
			this.$store.commit("client/updateNodeFieldValue", {fieldGroup, field, value})
		},
		updateBlogValue($event, field, payload) {
			let value = $event ? $event.target.value : payload
			this.$store.commit("client/updateNodeBlogValue", {field, value})
		},
    },
	async mounted() {
		try{

			const response = await this.$store.dispatch('client/checkIfNodeExsists');
			await this.$store.dispatch('client/checkIfScheduledPostExsists');

			if(response){
				this.nodeExsists = true;
				this.$store.dispatch('client/getSocialTokens', this.getClientSlug);

			}else{
				this.nodeExsists = false;
			}

		}catch(error){
			console.log(error);
		}
	},
    watch: {
		cancelComment(newVal){
			if(newVal == true){
				this.reloadCommentlist++;
				this.reloadBlogContent++;
			}
		},
		hasCurrentNodeBlogContentBeenEdited(newValue){
			if(newValue === true){
				this.reloadCommentlist++;
				this.$store.commit('client/setHasCurrentNodeBlogContentBeenEdited', false);
			}
		},
		hasCommentsBeenUpdated(newValue){
			if(newValue === true){
				this.reloadComponent++;
				this.reloadCommentlist++;
				console.log('hasCommentsBeenUpdated blog', this.reloadComponent);
				this.$store.commit('client/setHasCommentsBeenUpdated', false);
			}
		},
		getTaggedUser(user){
			if(Object.keys(user).length !== 0){
				this.$store.commit('client/setOpenCommentPanel', true);
			}
      	},
	},
  };
  </script>



<style lang="scss">
	@import "./../../assets/scss/components/blog.scss";
</style>