<template>
    <div class="social">
        <h1>Integrations</h1>
        <ul>
            <li>
                <span>
                    <div class="item-link">
                        <i class="fa-brands fa-facebook-f"></i>
                        Meta
                    </div>
                </span>
                <div class="item-status-delete">
                    <span class="status">
                        {{connectionMeta}}
                    </span>
                    <button class="btn-save btn-save--outline" @click.prevent="this.getSocialAccountStatus('meta')" >
                        <i class="fa-solid fa-eye"></i>
                        View
                    </button>
                    <button class="btn-close" @click.prevent="this.$store.dispatch('client/deleteSocialAccount', {socialItem: 'meta', username: this.getClientSlug})">
                        <i class="fa-solid fa-arrow-rotate-left" ></i>
                        Reset
                    </button>
                </div>
            </li>

            <li>
                <span>
                    <div class="item-link">
                        <i class="fa-brands fa-x-twitter"></i>
                        X
                    </div>
                </span>
                <div class="item-status-delete">
                    <span class="status">
                        {{connectionTwitter}}
                    </span>
                    <button class="btn-save btn-save--outline" @click.prevent="this.getSocialAccountStatus('twitter')">
                        <i class="fa-solid fa-eye"></i>
                        View
                    </button>
                    <button class="btn-close" @click.prevent="this.$store.dispatch('client/deleteSocialAccount', {socialItem: 'twitter', username: this.getClientSlug})">
                        <i class="fa-solid fa-arrow-rotate-left" ></i>
                        Reset
                    </button>
                </div>
            </li>

            <li>
                <span>
                    <div  class="item-link">
                        <i class="fa-brands fa-linkedin"></i>
                        Linkedin
                    </div>
                </span>
                <div class="item-status-delete">
                    <span class="status">
                        {{connectionLinkedin}}
                    </span>
                    <button class="btn-save btn-save--outline" @click.prevent="this.getSocialAccountStatus('linkedin')">
                        <i class="fa-solid fa-eye"></i>
                        View
                    </button>
                    <button class="btn-close" @click.prevent="this.$store.dispatch('client/deleteSocialAccount', {socialItem: 'linkedin', username: this.getClientSlug})">
                        <i class="fa-solid fa-arrow-rotate-left" ></i>
                        Reset
                    </button>
                </div>
            </li>

            <li>
                <span>
                    <div class="item-link">
                        <i class="fa-brands fa-google"></i>
                        Google
                    </div>
                </span>
                <div class="item-status-delete">
                    <span class="status">
                        {{connectionGoogle}}
                    </span>
                    <button class="btn-save btn-save--outline" @click.prevent="this.getSocialAccountStatus('google')">
                        <i class="fa-solid fa-eye"></i>
                        View
                    </button>
                    <button class="btn-close" @click.prevent="this.$store.dispatch('client/deleteSocialAccount', {socialItem: 'google', username: this.getClientSlug})">
                        <i class="fa-solid fa-arrow-rotate-left" ></i>
                        Reset
                    </button>
                </div>
            </li>

            <li>
                <span>
                    <div class="item-link">
                        <i class="fa-brands fa-wordpress-simple"></i>
                        Wordpress
                    </div>
                </span>
                <div class="item-status-delete">
                    <span class="status">
                        {{connectionWordpress}}
                    </span>
                    <button class="btn-save btn-save--outline" @click.prevent="this.getSocialAccountStatus('wordpress')">
                        <i class="fa-solid fa-eye"></i>
                        View
                    </button>
                    <button class="btn-close" @click.prevent="this.$store.dispatch('client/deleteSocialAccount', {socialItem: 'wordpress', username: this.getClientSlug})">
                        <i class="fa-solid fa-arrow-rotate-left" ></i>
                        Reset
                    </button>
                </div>
            </li>
        </ul>
        <div @click.prevent="this.$store.commit('client/setImportModalOpen', true)" class="btn-primary btn-primary--outline">
            <i class="fa-solid fa-file-import"></i>
            Import Data
        </div>
    </div>
</template>
<script>
import {mapGetters} from "vuex"
export default {
    watch:{
        'getSocial.socialAccountInformation': {
            async handler() {
                let promises = [];
                try{
                    promises.push(this.socialAccountIsConnected('meta'));
                    promises.push(this.socialAccountIsConnected('twitter'));
                    promises.push(this.socialAccountIsConnected('linkedin'));
                    promises.push(this.socialAccountIsConnected('google'));
                    promises.push(this.socialAccountIsConnected('wordpress'));
                    await Promise.all(promises);
                }catch(error){
                    console.log('created error account auth', error);
                }
            },
            deep: true
        }
    },
    data(){
        return{
            connectionMeta: 'Loading...',
            connectionTwitter: 'Loading...',
            connectionLinkedin: 'Loading...',
            connectionGoogle: 'Loading...',
            connectionWordpress: 'Loading...',
            platform: '',
        }
    },
    async created() {
        let promises = [];
        try{
            promises.push(this.socialAccountIsConnected('meta'));
            promises.push(this.socialAccountIsConnected('twitter'));
            promises.push(this.socialAccountIsConnected('linkedin'));
            promises.push(this.socialAccountIsConnected('google'));
            promises.push(this.socialAccountIsConnected('wordpress'));
            await Promise.all(promises);
        }catch(error){
            console.log('created error account auth', error);
        }
    },
    computed:{
        ...mapGetters("client", ["getClientSlug", 'getSocial', 'getCurrentNode']),
        ...mapGetters('auth', 'getAuthUsername'),
    },
    methods: {
        getSocialAccountStatus(platform){
            this.$store.commit('client/setBlogModalOpen', platform);
        },
        async socialAccountIsConnected(socialPlatform){
            try{
                if(socialPlatform === 'meta'){
                    if(this.getSocial.socialAccountInformation.meta.fbPageTokenStatus.is_valid && this.getSocial.socialAccountInformation.meta.fbUserTokenStatus.is_valid){
                        if(this.getSocial.socialAccountInformation.meta.fbPageName || this.getSocial.socialAccountInformation.meta.instaPageName){
                            this.connectionMeta = 'Connected';
                        }else{
                            this.connectionMeta =  'Not Connected';
                        }
                    }else{
                        this.connectionMeta =  'Not Connected';
                    }
                }
                if(socialPlatform === 'twitter'){
                    if(this.getSocial.socialAccountInformation.twitter.name){
                        this.connectionTwitter =  'Connected';
                    }else{
                        this.connectionTwitter =  'Not Connected';
                    }
                }
                if(socialPlatform === 'linkedin'){
                    if(this.getSocial.socialAccountInformation.linkedin.name && this.getSocial.socialAccountInformation.linkedin.pageName){
                        this.connectionLinkedin =  'Connected';
                    }else{
                        this.connectionLinkedin =  'Not Connected';
                    }
                }
                if(socialPlatform === 'google'){
                    if(this.getSocial.socialAccountInformation.google.name && this.getSocial.socialAccountInformation.google.GA4PropertyName){
                        this.connectionGoogle =  'Connected';
                    }else{
                        this.connectionGoogle =  'Not Connected';
                    }
                }
                if(socialPlatform === 'wordpress'){
                
                    if(this.getSocial.socialAccountInformation.wordpress.wordpressIsValid && this.getSocial.socialAccountInformation.wordpress.getPostsWorks){
                        this.connectionWordpress =  'Connected';
                    }else if(this.getSocial.socialAccountInformation.wordpress.wordpressIsValid === true && this.getSocial.socialAccountInformation.wordpress.getPostsWorks !== true){
                        this.connectionWordpress =  'Allowed to share';
                    }else if(this.getSocial.socialAccountInformation.wordpress.wordpressIsValid !== true && this.getSocial.socialAccountInformation.wordpress.getPostsWorks === true){
                        this.connectionWordpress =  'Allows to get posts';
                    }else if(this.getSocial.socialAccountInformation.wordpress.wordpressIsValid === false && this.getSocial.socialAccountInformation.wordpress.getPostsWorks === false){
                        this.connectionWordpress =  'Not Connected';
                    }else{
                        this.connectionWordpress =  'Not Connected';
                    }
                }
            }catch(error){
                console.log('socialAccountIsConnected error', error);
            }
        }
    }
}
</script>
<style lang="scss">
@import "./../../../../assets/scss/components/_social.scss";
</style>