<template>
  <div class="wywig-editor">
    <p class="preview-text" v-if="getOpenBlogEditorAddNodeForm === false">Content</p>
    <div id="my-toolbar" :class="{hide : getOpenBlogEditorAddNodeForm === false}">
      <button class="ql-gen ai-generate-icon" @click.prevent="this.$store.commit('client/setAIPopUpFields', {prop: 'modals/blog', val: true})" ><i class="fa-solid fa-wand-magic-sparkles"></i></button>
      <select class="ql-header">
        <option value="1"></option>
        <!-- Note a missing, thus falsy value, is used to reset to default -->
        <option value="2"></option>
        <option value="3"></option>
        <option value="4"></option>
        <option value="5"></option>
        <option value="6"></option>
      </select>
      <button class="ql-bold"></button>
      <button class="ql-italic"></button>
      <button class="ql-underline"></button>
      <button class="ql-strike"></button>
      <button class="ql-align" value=""></button>
      <button class="ql-align" value="center"></button>
      <button class="ql-align" value="right"></button>
      <button class="ql-align" value="justify"></button>

      <button class="ql-list" value="ordered"></button>
      <button class="ql-list" value="bullet"></button>
      
      <button class="ql-link"></button>
      <button class="comment-input" @click.prevent='this.onOpenMediaModal()'>
        <i class="fas fa-photo-video"></i>
      </button>
    </div>
    <QuillEditor ref="quillEditor" toolbar="#my-toolbar" @ready="onEditorReady" @textChange="onEditorChange" @selection-change="onSelectionChange()" :readOnly="getOpenBlogEditorAddNodeForm === false || getScheduledBlogPosts.hasbeenPublished === true"></QuillEditor>
    <div class="wywig-editor__footer">
      <span class="btn-primary btn-primary--outline wywig-editor__add-media" @click.prevent='this.onOpenMediaModal()' v-if="getOpenBlogEditorAddNodeForm"><i class="fa-solid fa-circle-plus"></i>Add Media</span>
      <span class="btn-primary btn-primary--outline wywig-editor__tag-user" @click.prevent="this.onTagUserBtn()"><i class="fa-solid fa-at"></i>Tag User</span>
    </div>
  </div>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill';
// import Delta from 'quill-delta';
import Quill from 'quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { mapGetters } from 'vuex';
//quill.insertEmbed with the 'video' format is not a built-in feature of Quill. You can use a custom blot to insert videos into the editor. Here’s an example of how you can create a custom VideoBlot and register it with Quill:
const BlockEmbed = Quill.import('blots/block/embed');
class VideoBlot extends BlockEmbed {
  static create(value) {
    const node = super.create();
    node.setAttribute('src', value);
    node.setAttribute('controls', '');
    return node;
  }

  static value(node) {
    return node.getAttribute('src');
  }
}
VideoBlot.blotName = 'add-video';
VideoBlot.tagName = 'video';
Quill.register(VideoBlot);
class ImageBlot extends BlockEmbed {
  static create(value) {
    const node = super.create();
    node.setAttribute('src', value);
    return node;
  }

  static value(node) {
    return node.getAttribute('src');
  }
}
ImageBlot.blotName = 'add-image';
ImageBlot.tagName = 'img';
Quill.register(ImageBlot);

const Inline = Quill.import('blots/inline');

class Span extends Inline {
  static create(value) {
    let node = super.create();
    node.setAttribute('comment-id', value);
    node.classList.add('comment-span');
    return node;
  }

  static formats(node) {
    return node.getAttribute('comment-id');
  }
}
Span.blotName = 'span';
Span.tagName = 'span';
Quill.register(Span);
export default {
  data() {
    return {
      generatedText: '<h1>This is AI generated text</h1>',
      mediaArray: [],
      highLightedText: {},
      forceUpdate: 0,
      usersArray: [],
    };
  },
  computed: {
    comment: {
            get() {
				return this.$store.getters["client/getComment"] || ""
            },
		},
    createComment: {
            get() {
				return this.$store.getters["client/getCreateComment"] || ""
            },
		},
    cancelComment: {
            get() {
				return this.$store.getters["client/getCancelComment"] || ""
            },
		},
    ...mapGetters({
        comments: 'client/getClientNodeComments'
      }),
      ...mapGetters("client", ["getTaggedUsers", "getOpenBlogEditorAddNodeForm", "getNodeBlogContentHTML", "getHasCommentsBeenUpdated", "getScheduledBlogPosts", 'getAIPopUpFields']),
      ...mapGetters("tools", ["getSelectedMediaMediaModal", "getAddMedia", "getMediaModalCurrentComponent"]),
  },
  watch: {
    getTaggedUsers: {
      handler(obj) {
        if(obj.modalIsOpen){
          this.onHighlightText();
        }else{
          this.removeSpanTags();
        }
      },
      deep: true
    },
    getTaggedUser: {
      handler(user) {
        if(Object.keys(user).length !== 0){
          document.querySelector('#addCommentInput').value += `@${user.username}`;
          this.$store.commit('client/setTaggedUser', {});
        }
      },
      deep: true
    },
    getAddMedia(){
      //When image has been selected in the media modal run the below
      if(this.getSelectedMediaMediaModal.length > 0 && this.getMediaModalCurrentComponent === 'Wyswig'){
        this.getSelectedMediaMediaModal.forEach(mediaSrc => {
          const quill = this.$refs.quillEditor.getQuill();
          const range = quill.getSelection(true);
          if (mediaSrc.includes('mp4')) {
            quill.insertEmbed(range.index, 'add-video', mediaSrc);
          } else {
            quill.insertEmbed(range.index, 'add-image', mediaSrc);
          }
        })
        this.$store.commit('tools/setAddMedia', false);
        this.$store.commit('tools/setSelectedMediaMediaModal', []);
      }
    },
    createComment(newVal){
      console.log('create comment!');
      if(newVal === true){
        console.log('create comment! === true', [this.comment.id, this.highLightedText.selectedText]);
        const quill = this.$refs.quillEditor.getQuill();
        if(this.highLightedText.selectedText){
          quill.deleteText(this.highLightedText.cursorPosition, this.highLightedText.selectedText.length);
          quill.insertText(this.highLightedText.cursorPosition, this.highLightedText.selectedText, { 'span': this.comment.id });
          this.$store.commit('client/setCreateComment', false);
        }
      }
    },
    cancelComment(newVal){
      if(newVal === true){
        document.querySelectorAll('.wywig-editor span').forEach(span => {
          span.classList.remove('active');
          console.log('span removed active.');
        })
        this.removeSpanTags();
        this.$store.commit('client/setCancelComment', false);
      }
    },
    'getAIPopUpFields.response': {
        deep: true,
          async handler(data) {
            this.$refs.quillEditor.setHTML(data);
            console.log('ai set blog content');
          }
      }
  },
  mounted() {
    this.$refs.quillEditor.setHTML(this.getNodeBlogContentHTML);
    this.onClickHighlightSpanTags();
    this.removeSpanTags();
    this.onClickRemoveClassesIfNotOnSpan();
  },
  methods: {
    onEditorReady() {
      const editor = this.$refs.quillEditor.getQuill();

      // Add paste event listener
      editor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        let ops = [];
        delta.ops.forEach(op => {
          if (op.insert && typeof op.insert === 'string') {
            ops.push({ insert: op.insert });
          }
        });
        delta.ops = ops;
        return delta;
      });
    },
    onHighlightText(){
      if(this.highLightedText.selectedText !== ''){
        const quill = this.$refs.quillEditor.getQuill();
        quill.deleteText(this.highLightedText.cursorPosition, this.highLightedText.selectedText.length);
        quill.insertText(this.highLightedText.cursorPosition, this.highLightedText.selectedText, { 'span': this.comment.id });
      }
    },
    onTagUserBtn(){
      this.$store.commit('client/setTaggedUsers', {prop: 'lastComponentClicked', val: 'addCommentInput'});
      this.$store.commit('client/setTaggedUsers', {prop: 'activeComponent', val: 'wyswig'});
      this.$store.commit('client/setTaggedUsers', {prop: 'modalIsOpen', val: true});
      this.$store.commit('client/setOpenCommentPanel', true)
      this.onHighlightText();
    },
    onOpenMediaModal(){
      this.$store.commit("tools/setIsOpenMediaModal", true);
      this.$store.commit("tools/setMediaModalCurrentComponent", 'Wyswig');
    },
    onClickRemoveClassesIfNotOnSpan(){
      document.addEventListener('click', function(event) {
        if (!event.target.matches('.comment-span') && !event.target.closest('.comment')) {
          if(document.querySelector('.wywig-editor span.active')){
            document.querySelector('.wywig-editor span.active').classList.remove('active');
          }
          if(document.querySelector(`.comment.active`)){
            document.querySelector(`.comment.active`).classList.remove('active');
          }
        }
    });
    },
    onClickHighlightSpanTags(){
      if(document.querySelectorAll('.comment-span').length > 0){
      document.querySelectorAll('.comment-span').forEach(comment => {
        const id = comment.getAttribute('comment-id');
        comment.addEventListener('click', () => {
          this.$store.commit('client/setOpenCommentPanel', true);
          document.querySelectorAll('.comment-span.active').forEach(activeComment => {
            activeComment.classList.remove('active');
            const activeId = activeComment.getAttribute('comment-id');
            document.querySelector(`.comments-container [comment-id="${activeId}"]`).classList.remove('active');
          });
        comment.classList.add('active');
        document.querySelector(`.comments-container [comment-id="${id}"]`).classList.add('active');
        document.querySelector(`.comments-container [comment-id="${id}"]`).scrollIntoView({ behavior: 'smooth' });
        })
      })
    }
    },
    removeSpanTags(){
      if(document.querySelectorAll('.comment-span').length > 0){
        document.querySelectorAll('.comment-span').forEach(comment => {
          const id = comment.getAttribute('comment-id');
          //IF COMMENT IS NOT IN THE LIST, THEN REMOVE THE SPAN TAG IN WYWIG
          if (!this.comments.some(c => c.id === id)) {
            const textNode = document.createTextNode(comment.textContent);
            comment.parentNode.replaceChild(textNode, comment);
          }
          const found = this.comments.find(c => c.id === id && c.resolved === true);
          if (found) {
            const textNode = document.createTextNode(comment.textContent);
            comment.parentNode.replaceChild(textNode, comment);
          }
        });
      }
    },
    onSelectionChange(){
      var div = document.querySelector('.ql-editor');
      var selection = window.getSelection();
      if (selection.rangeCount > 0) {
        var range = selection.getRangeAt(0);
        if (div.contains(range.commonAncestorContainer)) {
          this.highLightedText = {
            selectedText: selection.toString(),
            cursorPosition: this.$refs.quillEditor.getQuill().getSelection().index
          }
        }
      }
    },
    getInputSelection() {
      var div = document.querySelector('.ql-editor');
      var selection = window.getSelection();
      if (selection.rangeCount > 0) {
        var range = selection.getRangeAt(0);
        if (div.contains(range.commonAncestorContainer)) {
          var selectedText = selection.toString();
          var quill = this.$refs.quillEditor.getQuill();
          var cursorPosition = quill.getSelection().index;
          quill.deleteText(cursorPosition, selectedText.length);
          quill.insertText(cursorPosition, selectedText, { 'span': this.comment.id });
        }
      }
    },
    onEditorChange(){
      console.log('onEditorChange');
      this.$store.commit('client/setNodeBlogContentHTML', this.$refs.quillEditor.getHTML());
    },
    applyNormalText() {
      var quill = this.$refs.quillEditor.getQuill();
      quill.format('header', false);
    }
  },
  components: {
    QuillEditor,
  },
};
</script>


<style lang="scss" scoped>
	@import "../../../assets/scss/components/blog.scss";
</style>