<template>

    <div class="social-media">

        <div class="social-media__main-image">

            <img :src="mainImage.url" v-if="mainImage.type === 'image'">
            <video controls width="381" height="381" :src="mainImage.url" v-if="mainImage.type === 'video'"></video>
            <button class="btn-primary btn-primary--outline btn-feauture-image" @click.prevent="onOpenMediaModal()" v-if="showFeatureImageBtn()"><i class="fa-solid fa-upload"></i>Upload feature image</button>

        </div>

        <div class="social-media__small-images">

            <draggable 
            v-model="mediaArray"
            tag="transition-group"
            :component-data="{name:'fade'}"
            itemKey="id"
            handle=".drag-handle"
            >

                <template #item="{element}">
                    <div class="social-media__small-image" :class="{'social-media__small-image--video' : element.type === 'video'}">
                        <img class="drag-handle" v-if="element.type === 'image'" :src="element.url">
                        <video class="drag-handle" width="65" height="65" :src="element.url" v-if="element.type === 'video'"></video>
                        <i v-if="getSocial.post.hasbeenPublished === false && getSocial.currentPageSectionThatIsOpen !== 'overview'" class="fa-sharp fa-light fa-xmark" @click.prevent="removeElement(element.id)"></i>
                    </div>
                </template>

            </draggable>

        </div>

    </div>
    
</template>


<script>

import {mapGetters} from "vuex"

import draggable from 'vuedraggable'

export default {

    data() {

        return {

            drag: false,

        }

    },

    components: {

        draggable,
    },

    computed:{

        ...mapGetters("client", ["getClientSlug", 'getSocial']),

        ...mapGetters("tools", ["getSelectedMediaMediaModal", "getAddMedia", "getMediaModalCurrentComponent"]),

        mainImage(){

            if(this.getSocial.post.media.length > 0){

                let media = this.getSocial.post.media[0];
                
                   
                let type;
                let urlWithoutParameters = media.split("?")[0]; // Remove query parameters
                if (urlWithoutParameters.match(/\.(jpeg|jpg|gif|png)$/) != null || urlWithoutParameters.includes('image')) {
                    type = 'image';
                } else {
                    type = 'video';
                }
                return { url: media, type: type };


            }else{

                return false;

            }

        },

        mediaArray : {

            get() {

				let responseArr = this.$store.getters["client/getSocial"].post.media || [];

                responseArr = responseArr.map((url, index) => {
                    let type;
                    let urlWithoutParameters = url.split("?")[0]; // Remove query parameters
                    if (urlWithoutParameters.match(/\.(jpeg|jpg|gif|png)$/) != null || urlWithoutParameters.includes('image')) {
                        type = 'image';
                    } else {
                        type = 'video';
                    }
                    return { url: url, id: index, type: type };
                });

                return responseArr;

			},

			set(value) {

				let urls = value.map(item => item.url);

                this.$store.commit('client/setSocial', {prop: 'post/media', val: urls});

			},

        },

    },
    methods: {

        onOpenMediaModal(){

            this.$store.commit("tools/setIsOpenMediaModal", true);

            this.$store.commit("tools/setMediaModalCurrentComponent", 'SocialMedia');

        },
        removeElement(id) {

            this.mediaArray = this.mediaArray.filter(element => element.id !== id);
            
        },
        showFeatureImageBtn(){

            let val = 0;

            if(this.getSocial.post.media.length === 0){
                val++;
            }
            if(this.getSocial.calendarIsOpen === ''){
                val++;
            }
            if(this.getSocial.currentPageSectionThatIsOpen !== 'overview'){
                val++;
            }

            if(val === 3){
                return true;
            }else{
                return false;
            }
        }

    },
    mounted(){
        this.showFeatureImageBtn();
    },
    watch:{
    
    getAddMedia(){

      //When image has been selected in the media modal run the below

      if(this.getSelectedMediaMediaModal.length > 0 && this.getMediaModalCurrentComponent === 'SocialMedia'){

        let arr = [...this.getSocial.post.media];

        this.getSelectedMediaMediaModal.forEach(mediaItem => {
            
            arr.push(mediaItem);

        });

        this.$store.commit('client/setSocial', {prop: 'post/media', val: arr});

        this.$store.commit('tools/setAddMedia', false);

        this.$store.commit('tools/setSelectedMediaMediaModal', []);

      }

    },

  }

}

</script>


<style lang="scss">

@import "./../../../assets/scss/components/_social.scss";

</style>