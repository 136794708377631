<template>
    <div class="social-modal" :class="{'social-modal__calendar' : getSocial.calendarIsOpen}" v-if="getSocial.modalIsOpen || getSocial.calendarIsOpen || getSocial.accountSelectIsOpen">
        <div class="social-modal__modal">
            <div v-if="getSocial.modalIsOpen">
                <button class="btn-close social-modal__exit" @click.prevent="this.$store.commit('client/setSocial', {prop: 'modalIsOpen', val: false})"><i class="fa-sharp fa-solid fa-xmark"></i>Close</button>
                <AccountsAuth />
            </div>
            <Calendar v-if="getSocial.calendarIsOpen" />
            <div v-if="getSocial.accountSelectIsOpen">
                <button class="btn-close" @click.prevent="this.$store.commit('client/setSocial', {prop: 'accountSelectIsOpen', val: false})"><i class="fa-sharp fa-solid fa-xmark"></i>Close</button>
                <SocialPageSelect v-if="getSocial.accountSelectIsOpen === 'linkedin'"/>
                <MetaPageSelect v-if="getSocial.accountSelectIsOpen === 'meta'"/>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import AccountsAuth from "../section/Social/accounts/AccountsAuth.vue";
import Calendar from '../section/Social/Calendar.vue';
import SocialPageSelect from '../section/Social/accounts/SocialPageSelect.vue';
import MetaPageSelect from '../section/Social/accounts/MetaPageSelect.vue';

export default {
    components: {
        AccountsAuth,
        Calendar,
        SocialPageSelect,
        MetaPageSelect
    },
    computed:{

    ...mapGetters("client", ["getClientSlug", 'getSocial']),

    },
    watch:{
        'getSocial.modalIsOpen': function (val) {
            if(val){
                document.querySelector('body').classList.add('lock-body');
            }else{
                document.querySelector('body').classList.remove('lock-body');
            }
        },
        'getSocial.calendarIsOpen': function (val) {
            if(val){
                document.querySelector('body').classList.add('lock-body');
            }else{
                document.querySelector('body').classList.remove('lock-body');
            }
        },
        'getSocial.accountSelectIsOpen': function (val) {
            if(val){
                document.querySelector('body').classList.add('lock-body');
            }else{
                document.querySelector('body').classList.remove('lock-body');
            }
        }
    },
}
</script>

<style lang="scss">

@import "./../../../src/assets/scss/components/_socialModal.scss";

</style>