<template>
    <div
        class="dropzone-container"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
      >
        <input
          type="file"
          multiple
          name="file"
          id="fileInput"
          class="hidden-input"
          @change="onChange"
          ref="file"
          accept=".jpg,.jpeg,.png,.mp4"
        />
  
        <label for="fileInput" class="file-label">
          <div v-if="isDragging">Release to drop files here. (100mb max size)</div>
          <div v-else>Drop files here or <u>click here</u> to upload. (100mb max size)</div>
        </label>
      </div>
</template>
<script>
import {mapGetters} from "vuex"
export default {
    data() {
      return {
        isDragging: false,
      };
    },
    computed:{
      ...mapGetters("client", ["getClientSlug"]),
      ...mapGetters("tools", ["getDroppedFiles"]),
    },
    methods: {
      async onChange() {
        try{
          await this.$store.dispatch('tools/uploadMedia', {files: [...this.$refs.file.files], slug: this.getClientSlug}); 
        }catch(error){
          alert('DropFile catch error: '+error+ 'error');
          console.log('DropFile catch error: '+error+ 'error');
        }
      },
      dragover(e) {
        e.preventDefault();
        this.isDragging = true;
      },
      dragleave() {
        this.isDragging = false;
      },
      drop(e) {
        e.preventDefault();
        this.$refs.file.files = e.dataTransfer.files;
        this.onChange();
        this.isDragging = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
@import "./../../../assets/scss/components/_mediaModal.scss";
</style>