<template>
    <div class="blog-modal" v-if="getImportModalOpen">

        <div class="blog-modal__modal import-modal">

            <button class="btn-link social-modal__exit btn-hover" @click.prevent="this.$store.commit('client/setImportModalOpen', false)"><i class="fas fa-times"></i>Close</button>

            <div class="import-modal__wrap">

                <h1>Import</h1>
                <p>Import social/blog posts into the content tree.</p>
                <p>Make sure you have authenticated all the social platforms first</p>
            
                <ul>
                    <li :class="{disabled : facebookNoAuth}">
                        <span>
                            <div class="item-link">
                                <i class="fa-brands fa-facebook-f"></i>
                                Facebook
                            </div>
                        </span>
                        <div class="item-status-delete">
                            <span class="status">
                                {{facebookStatus}}
                            </span>
                            <button v-if="!hideImportBtnFacebook" @click.prevent="this.importPosts('facebook')" class="btn-import btn-save btn-save--outline">
                                <i class="fa-solid fa-download"></i>
                                Import
                            </button>
                        </div>
                    </li>
                    <li :class="{disabled : instagramNoAuth}">
                        <span>
                            <div class="item-link">
                                <i class="fa-brands fa-instagram"></i>
                                Instagram
                            </div>
                        </span>
                        <div class="item-status-delete">
                            <span class="status">
                                {{instagramStatus}}
                            </span>
                            <button v-if="!hideImportBtnInstagram" @click.prevent="this.importPosts('instagram')" class="btn-import btn-save btn-save--outline">
                                <i class="fa-solid fa-download"></i>
                                Import
                            </button>
                        </div>
                    </li>
                    <li :class="{disabled : linkedinNoAuth}">
                        <span>
                            <div class="item-link">
                                <i class="fa-brands fa-linkedin"></i>
                                Linkedin
                            </div>
                        </span>
                        <div class="item-status-delete">
                            <span class="status">
                                {{linkedinStatus}}
                            </span>
                            <button v-if="!hideImportBtnLinkedin"  @click.prevent="this.importPosts('linkedin')" class="btn-import btn-save btn-save--outline">
                                <i class="fa-solid fa-download"></i>
                                Import
                            </button>
                        </div>
                    </li>
                    <li :class="{disabled : wordpressNoAuth}">
                        <span>
                            <div class="item-link">
                                <i class="fa-brands fa-wordpress-simple"></i>
                                Wordpress
                            </div>
                        </span>
                        <div class="item-status-delete">
                            <span class="status">
                                {{wordpressStatus}}
                            </span>
                            <button v-if="!hideImportBtnWordpress" @click.prevent="this.importPosts('wordpress')" class="btn-import btn-save btn-save--outline">
                                <i class="fa-solid fa-download"></i>
                                Import
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
     
        </div>

    </div>

</template>
<script>
import {mapGetters} from "vuex"
import { useRoute } from 'vue-router';
export default {
    data(){
        return{
            loading: true,
            facebookStatus: 'Waiting auth',
            instagramStatus: 'Waiting auth',
            linkedinStatus: 'Waiting auth',
            wordpressStatus: 'Waiting auth',

            facebookNoAuth: true,
            instagramNoAuth: true,
            linkedinNoAuth: true,
            wordpressNoAuth: true,

            justImportedFacebook: false,
            justImportedInstagram: false,
            justImportedLinkedin: false,
            justImportedWordpress: false,

            hideImportBtnFacebook: false,
            hideImportBtnInstagram: false,
            hideImportBtnLinkedin: false,
            hideImportBtnWordpress: false
        }
    },
    async mounted(){
        const route = useRoute();
        const clientParam = route.params.client;
        await this.$store.dispatch('client/getImportedContentTree', clientParam);
    },
    computed:{
        ...mapGetters("client", ["getClientSlug", 'getSocial', 'getBlogModalOpen', 'getCurrentNode', 'getImportModalOpen', 'getImportedContentTree']),
        ...mapGetters("auth", ["getAuthUsername"]),
    },
    watch:{
        'getImportedContentTree':{
            async handler() {
                try{
                    if(this.getImportedContentTree.nodes.length > 0){
                        this.hasPosts('facebook');
                        this.hasPosts('instagram');
                        this.hasPosts('linkedin');
                        this.hasPosts('wordpress');
                    }
                }catch(error){
                    console.log('getImportedContentTree watch catch error', error);
                }
            },
            deep: true
        },
        'getSocial.socialAccountInformation': {
            async handler() {
                let promises = [];
                try{
                    promises.push(this.socialAccountIsConnected('meta'));
                    promises.push(this.socialAccountIsConnected('linkedin'));
                    promises.push(this.socialAccountIsConnected('wordpress'));
                    await Promise.all(promises);
                }catch(error){
                    console.log('created error account auth', error);
                }
            },
            deep: true
        }
    },
    methods:{
        async hasPosts(platform){
            if(this.getImportedContentTree.nodes && this.getImportedContentTree.nodes.length > 0){
                const obj = this.getImportedContentTree.nodes.find(ob => ob.platform === platform);
                const categoryNode = obj && obj.nodesData && obj.nodesData.category ? obj.nodesData.category : false;
                const nodes = categoryNode.id ? obj.nodesData.nodes.filter(node => node.category === categoryNode.id) : false;
                const count = nodes.length ? nodes.length : 0;
                if(categoryNode){
                    if(platform === 'facebook'){
                        this.facebookStatus = 'Imported';
                        if(this.justImportedFacebook && this.hideImportBtnFacebook === false){
                            alert(`Imported ${count} ${platform} posts. These can be accessible in the list view.`);
                        }
                        // this.hideImportBtnFacebook = true;
                        // this.facebookNoAuth = false;
                    }
                    if(platform === 'instagram'){
                        this.instagramStatus = 'Imported';
                        if(this.justImportedInstagram && this.hideImportBtnInstagram === false){
                            alert(`Imported ${count} ${platform} posts. These can be accessible in the list view.`);
                        }
                        // this.hideImportBtnInstagram = true;
                        // this.instagramNoAuth = false;
                    }
                    if(platform === 'linkedin'){
                        this.linkedinStatus = 'Imported';
                        if(this.justImportedLinkedin && this.hideImportBtnLinkedin === false){
                            alert(`Imported ${count} ${platform} posts. These can be accessible in the list view.`);
                        }
                        // this.hideImportBtnLinkedin = true;
                        // this.linkedinNoAuth = false;
                    }
                    if(platform === 'wordpress'){
                        this.wordpressStatus = 'Imported';
                        if(this.justImportedWordpress && this.hideImportBtnWordpress=== false){
                            alert(`Imported ${count} ${platform} posts. These can be accessible in the list view.`);
                        }
                        // this.hideImportBtnWordpress = true;
                        // this.wordpressNoAuth = false;
                    }
                }
            }else{
                if(platform === 'facebook'){
                    if(this.justImportedFacebook){
                        alert(`There was no posts to import`);
                    }
                    this.facebookNoAuth = false;
                    this.facebookStatus = 'No data';
                }
                if(platform === 'instagram'){
                    if(this.justImportedInstagram){
                        alert(`There was no posts to import`);
                    }
                    this.instagramNoAuth = false;
                    this.instagramStatus = 'No data';
                }
                if(platform === 'linkedin'){
                    if(this.justImportedLinkedin){
                        alert(`There was no posts to import`);
                    }
                    this.linkedinNoAuth = false;
                    this.linkedinStatus = 'No data';
                }
                if(platform === 'wordpress'){
                    if(this.justImportedWordpress){
                        alert(`There was no posts to import`);
                    }
                    this.wordpressNoAuth = false;
                    this.wordpressStatus = 'No data';
                }
            }
        },
        showLoader(){
			this.$store.commit('client/setLoaders', {prop: 'hidePageLoader', val: false})
			this.$store.commit('client/setLoaders', {prop: 'hideContentTreeLoader', val: false})
        },
        async importPosts(platform){
            if(platform === 'facebook' && this.facebookNoAuth === false){
                this.showLoader();
                this.$store.commit('client/setLoaders', {prop: 'hideContentTreeLoader', val: true});
                this.facebookStatus = 'Importing';
                this.justImportedFacebook = true;
                await this.$store.dispatch('client/scrapeSocialPlatforms', { platform: 'facebook' });
            }
            if(platform === 'instagram' && this.instagramNoAuth === false){
                this.showLoader();
                this.instagramStatus = 'Importing';
                this.justImportedInstagram = true;
                await this.$store.dispatch('client/scrapeSocialPlatforms', { platform: 'instagram' });
            }
            if(platform === 'linkedin' && this.linkedinNoAuth === false){
                this.showLoader();
                this.linkedinStatus = 'Importing';
                this.justImportedLinkedin = true;
                await this.$store.dispatch('client/scrapeSocialPlatforms', { platform: 'linkedin' });
            }
            if(platform === 'wordpress' && this.wordpressNoAuth === false){
                this.showLoader();
                await this.$store.dispatch('client/getWordpressACFfields', { platform: 'wordpress' });
                this.wordpressStatus = 'Importing';
                this.justImportedWordpress = true;
            }
        },
        async socialAccountIsConnected(socialPlatform){
            try{
                if(socialPlatform === 'meta'){
                    if(this.getSocial.socialAccountInformation.meta.fbPageTokenStatus && this.getSocial.socialAccountInformation.meta.fbPageTokenStatus.is_valid && this.getSocial.socialAccountInformation.meta.fbUserTokenStatus && this.getSocial.socialAccountInformation.meta.fbUserTokenStatus.is_valid){
                        if(this.getSocial.socialAccountInformation.meta.fbPageName){
                            if(!this.hideImportBtnFacebook){
                                this.facebookStatus = 'Ready to import';
                            }
                            this.facebookNoAuth = false;
                        }else{
                            if(!this.hideImportBtnFacebook){
                                this.facebookStatus =  'Auth failed';
                                this.facebookNoAuth = true;
                            }
                        }
                        if(this.getSocial.socialAccountInformation.meta.instaPageName){
                            if(!this.hideImportBtnInstagram){
                                this.instagramStatus = 'Ready to import';
                            }
                            this.instagramNoAuth = false;
                        }else{
                            if(!this.hideImportBtnInstagram){
                                this.instagramStatus =  'Auth failed';
                                this.instagramNoAuth = true;
                            }
                        }
                    }else{
                        if(!this.hideImportBtnFacebook){
                            this.facebookStatus =  'Auth failed';
                            this.facebookNoAuth = true;
                        }
                        if(!this.hideImportBtnInstagram){
                            this.instagramStatus =  'Auth failed';
                            this.instagramNoAuth = true;
                        }
                    }
                }
                if(socialPlatform === 'linkedin'){
                    if(this.getSocial.socialAccountInformation.linkedin.name && this.getSocial.socialAccountInformation.linkedin.pageName){
                        if(!this.hideImportBtnLinkedin){
                            this.linkedinStatus =  'Ready to import';
                        }
           
                        this.linkedinNoAuth = false;
                    }else{
                        this.linkedinStatus =  'Auth failed';
                        this.linkedinNoAuth = true;
                    }
                }
                if(socialPlatform === 'wordpress'){
                    if(this.getSocial.socialAccountInformation.wordpress.getPostsWorks){
                        if(!this.hideImportBtnWordpress){
                            this.wordpressStatus =  'Ready to import';
                        }
                        this.wordpressNoAuth = false;
                    }else{
                        this.wordpressStatus =  'Auth failed';
                        this.wordpressNoAuth = true;
                    }
                }
            }catch(error){
                console.log('socialAccountIsConnected error', error);
            }
        }
    },
}
</script>
<style lang="scss">
@import "./../../../src/assets/scss/components/importModal.scss";
</style>