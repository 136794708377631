<template>
    <div class="media-list">
        <div class="media-list__image" v-for="media in getMediaList" :key="media"  :class="{active : isActive(media), video : isVideo(media)}"  @click.prevent="onSelect(media)">
            <video style="width:100%" :src="media" v-if="isVideo(media)"></video>
            <img v-else :src="media">
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
export default {
    computed:{
        ...mapGetters("tools", ["getMediaModal", "getSelectedMediaMediaModal"]),
        ...mapGetters("client", ["getMediaList"]),
    },
    methods:{
        isActive(url){
            return this.getSelectedMediaMediaModal.find(media => media === url);
        },
        isVideo(url){
            return url.includes('mp4');
        },
        onSelect(url){
            this.getSelectedMediaMediaModal.find(media => media === url) ? this.$store.commit('tools/setRemoveSelectedMediaMediaModal', url) : this.$store.commit('tools/setPushSelectedMediaMediaModal', url)
        },
    },
    mounted(){
        this.$store.dispatch('client/fetchMediaList');
    }
}
</script>

<style lang="scss" scoped>
@import "./../../../assets/scss/components/_mediaModal.scss";
</style>