<template>
    <div class="blog-publish-buttons" :class="{'allowedToPost' : allowedToPost}">
        <div class="blog-publish-buttons__btn-wrap">
            <button class="btn-primary btn-primary--outline blog-calendar-btn" @click="this.$store.commit('client/setSocial', {prop: 'calendarIsOpen', val: true})">Calendar</button>
            <div class="blog-calendar-btn-wrap">
                <span class="blog-calendar-text" v-if="getScheduledBlogPosts.hasbeenPublished === true">Published on:</span>
                <span class="blog-calendar-text" v-if="getScheduledBlogPosts.hasbeenPublished === false">Auto Publish on:</span>
                <VueDatePicker minutes-grid-increment="5" minutes-increment="5"  :disabled="getScheduledBlogPosts.hasbeenPublished" :min-date="new Date()" :max-date="new Date(Date.now() + 60 * 24 * 60 * 60 * 1000)" format="dd/MM/yyyy hh:mm a" v-model="publishDate"></VueDatePicker>
            </div>
            <div class="blog-save-btns">
                <button class="btn-save btn-save--outline" v-if="getScheduledBlogPosts.hasbeenPublished === false" @click.prevent="this.$store.dispatch('client/saveBlog')"><i class="fa-solid fa-floppy-disk"></i> Save Draft</button>
                <button class="btn-save" v-if="allowedToPost === true && getScheduledBlogPosts.hasbeenPublished === false" @click.prevent="schedulePost"><i class="fa-solid fa-clock"></i>Schedule</button>
                <button class="btn-save" v-if="allowedToPost === false && getScheduledBlogPosts.hasbeenPublished === false" @click.prevent="schedulePost"><i class="fa-solid fa-clock"></i>Submit for Approval</button>
            </div>
        </div>
    </div>
</template>
<script>

import {mapGetters} from "vuex"
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
    data(){
        return {
            hasChangedNumber: false
        }
    },
    computed:{
        ...mapGetters("auth", ["getAuthData", "getAuthRoleName"]),
        ...mapGetters("client", ["getBlogContentIsInDB", "getClientData", "getCurrentNode", "getClientSlug", 'getScheduledBlogPosts', "getSocial", "getScheduledBlogPosts", "getCurrentNodeBlogContent"]),
        allowedToPost(){
            // if(this.getAuthRoleName === 'Super Admin'){
            // return true;
            // }else if(this.getClientData.mainContact.username === this.getAuthData.username){
            //     return true;
            // }else{
            //     return false;
            // }
            if(this.getCurrentNode.fieldGroups.status.fields.status.value === 'Approved'){
                return true;
            }else{
                if(this.getClientData.mainContact && this.getClientData.mainContact.username === this.getAuthData.username){
                    return true;
                }else{
                    return false;
                }
            }
        },
        publishDate: {
            get() {
                //SAVE DRAFT BUTTON DOES NOT SAVE THE DATE AS IT GO PAST THE NEW DATE() (CURRENT DATE) - IT ONLY SAVES WHEN SCHEDULE HIT
                if(this.getBlogContentIsInDB || this.hasChangedNumber){
                    console.log('publishDate get():', this.getCurrentNodeBlogContent.date)
                    return this.getCurrentNodeBlogContent.date;
                
                }else{
                    let currentDate = new Date();
                    let minutes = currentDate.getMinutes();
                    let remainder = minutes % 5;
                    if (remainder !== 0) {
                        // Round to the nearest 5
                        currentDate.setMinutes(minutes + 5 - remainder);
                    }
                    console.log('step1-b:', currentDate)
                    return currentDate;
                }
            },
            set(value) {
                this.hasChangedNumber = true;
                console.log('step2', value);
                this.$store.commit('client/setCurrentNodeBlogContent', {prop: 'date', val: value});
                this.$store.commit('client/setScheduledBlogPost', {prop: 'date', val: value});
            }
        },
    },
    methods:{
        async schedulePost(){
            try{
                this.$store.commit('client/setHidePageLoader', false);
                const isNodePosting = await this.$store.dispatch("client/isNodePosting");
                if(isNodePosting.status === false){
                    if(this.getSocial.socialAccountInformation.wordpress.wordpressIsValid && this.getCurrentNodeBlogContent.featuredImageURL && this.getCurrentNodeBlogContent.title){
                        if(this.allowedToPost){
                            await this.$store.dispatch('client/scheduleBlogPost')
                        }else{
                            await this.$store.dispatch('client/saveBlog');
                            await this.$store.dispatch('client/notifyMainContactForApproval');
                        }
                    }else{
                        let errors = [];
                        if(!this.getSocial.socialAccountInformation.wordpress.wordpressIsValid){
                            errors.push('Wordpress needs authentication');
                        }
                        if(!this.getCurrentNodeBlogContent.featuredImageURL){
                            errors.push('Featured image is required');
                        }
                        if(!this.getCurrentNodeBlogContent.title){
                            errors.push('Title is required');
                        }
                        alert(errors.join(', '));
                    }
                }else{
                    alert('Node is currently posting to platform - Reload Shortly');
                }
                this.$store.commit('client/setHidePageLoader', true);
            }catch(error){
                console.log('schedulePost', error);
            }
        }
    },
    components: { VueDatePicker },
}
</script>
<style lang="scss">
@import "./../../../assets/scss/components/blog.scss";
@import "vue3-date-time-picker/src/Vue3DatePicker/style/main.scss";
</style>