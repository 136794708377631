<template>

    <div class="social disable">

        <div class="social__container"  v-if="getIsCreatingNewNode === false && getWaitingForPostToPublish === false">
            <!-- <test/> -->
            
            <div class="bk-king">

                <div class="social__top-row">

                    <StatusBar />

                </div>

                <div class="social__middle-row">

                    <Media />

                    <Caption />

                </div>

            </div>

        </div>

    </div>
    
</template>


<script>

import {mapGetters} from "vuex"
import Media from './Media.vue';
import Caption from './Caption.vue';
import StatusBar from './StatusBar.vue';

export default {
    components: {
        Media,
        Caption,
        StatusBar,
    },
    data(){
        return{
            nodeExsists: false
        }
    },
    computed:{

        ...mapGetters("client", ["getClientSlug", 'getSocial', "getWaitingForPostToPublish", "getIsCreatingNewNode"]),

        ...mapGetters("auth", ["getAuthUsername"]),

    },
    async mounted(){

        console.log('currentPageSectionThatIsOpen', this.getSocial.currentPageSectionThatIsOpen);

        try{

            const response = await this.$store.dispatch('client/checkIfNodeExsists');

            if(response){

                this.nodeExsists = true;

                await this.init();

            }else{

                this.nodeExsists = false;

            }

        }catch(error){
            console.log(error);
        }

    },
    methods:{

        async init(){

            try{

                await this.$store.dispatch('client/socialInit', this.getClientSlug);

            }catch(error){

                console.log('social init error:', error);

            }

        },
    }

}

</script>


<style lang="scss">

@import "./../../../assets/scss/components/_social.scss";

</style>