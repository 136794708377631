<template>
    <div class="social" :class="{'social__comments-open' : getOpenCommentPanel, social__live : getSocial.post.livePostLinks.facebook.url || getSocial.post.livePostLinks.twitter.url, disable : getSocial.currentPageSectionThatIsOpen === 'overview' ? true : false}">
        
        <div class="social__container"  v-if="getIsCreatingNewNode === false && getWaitingForPostToPublish === false">
            <div class="social-inner-wrap">
                <h1>Social</h1>
                <div class="social__title-and-status-bar">
                    <div class="social-auth-btn-wrap"><button v-if="getSocial.currentPageSectionThatIsOpen === 'overview' ? false : true" class="btn-primary btn-primary--outline social-auth-btn-2" @click.prevent="this.$store.commit('client/setSocial', {prop: 'modalIsOpen', val: true})" ><i class="fa-solid fa-user"></i>Integrations</button> </div>
                    <StatusBar />
                </div>
                <div class="bk-king">
                    <div class="social__top-row">
                        <SocialAccountSelect v-if="getSocial.currentPageSectionThatIsOpen === 'overview' ? false : true" />
                        <PublishDate />
                    </div>
                    <div class="social__middle-row">
                        <Media />
                        <Caption />
                    </div>
                    <div class="social__bottom-row">
                        <SchedulePost v-if="getSocial.currentPageSectionThatIsOpen === 'overview' ? false : true" />
                    </div>
                </div>
            </div>
        </div>
        <CommentSidebar v-if="getIsCreatingNewNode === false" />
    </div>
</template>


<script>

import {mapGetters} from "vuex"
import SocialAccountSelect from './socialAccountSelect.vue';
import PublishDate from './PublishDate.vue';
import Media from './Media.vue';
import Caption from './Caption.vue';
import SchedulePost from './SchedulePost.vue';
import StatusBar from './StatusBar.vue';
import CommentSidebar from '../../lists/CommentSidebar.vue';

export default {
    components: {
        SocialAccountSelect,
        PublishDate,
        Media,
        Caption,
        SchedulePost,
        StatusBar,
        CommentSidebar,
    },
    computed:{
        ...mapGetters("client", ["getClientSlug", 'getSocial', "getWaitingForPostToPublish", "getIsCreatingNewNode", "getOpenCommentPanel"]),
        ...mapGetters("auth", ["getAuthUsername"]),
    },
}

</script>

<style lang="scss">
@import "./../../../assets/scss/components/_social.scss";
</style>