<template>
    <div v-if="getSocial.ACFModalIsOpen" class="ai-pop-up-wrap">
        <div class="ai-generate-fields" v-if="loader === false">
            <label for="voice">Select content fields</label>
            <div v-if="getACFPickerData && getACFPickerData.length > 0">
                <multiselect v-model="value" tag-placeholder="Add this as new tag" placeholder="Search or add a tag" label="name" track-by="code" :options="options" :multiple="true" :taggable="true" @tag="addTag"></multiselect>
            </div>
            <div v-else>
                <p>Waiting for fields.</p>
            </div>
            <div class="btn-wrap">
              <button class="btn-save" @click.prevent="onSubmit()"><i class="fa-solid fa-save"></i>Save</button>
              <button class="btn-close" @click.prevent="this.$store.commit('client/setSocial', {prop: 'ACFModalIsOpen', val: false})"><i class="fas fa-times"></i>Close</button>
            </div>
        </div>
        <div v-if="loader">
            <h2 style="color: white;">Loading...</h2>
        </div>
    </div>
</template>


<script>
import { mapGetters } from 'vuex';
import multiselect from 'vue-multiselect';

export default {
  data(){
    return {
      open: false,
      loader: false,
      value: [],
      options: []
    }
  },
  components: { multiselect },
  computed: {
    ...mapGetters('client', ['getSocial', 'getACFPickerData']),
  },
  mounted(){
    this.$store.commit('client/setHidePageLoader', false);
    this.setOptions(this.getACFPickerData);
  },
  watch:{
    getACFPickerData(data){
        this.setOptions(data);
    }
  },
  methods: {
    addTag (newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.options.push(tag)
      this.value.push(tag)
    },
    setOptions(data){
        this.options.length === 0 && data.length > 0 ? this.options = data.map(field => { return {name: field, code: field} }) : [];
        if(data.length === 1){
            alert('We could only get post_content. If there is flexible content, make sure you have activated the REST API settings in ACF admin area and that is later or equal to v5.11');
        }
    },
    async onSubmit(){
        console.log('onSubmit');
        try{
            if(this.value && this.value.length > 0){
                const data = {
                    platform: 'wordpress',
                    fields: this.value.map(fieldObj => fieldObj.name)
                };
                console.log('on submit data GET WORDPRESS FIELDS', data);
                this.$store.commit('client/setHidePageLoader', false);
                await this.$store.dispatch('client/scrapeSocialPlatforms', data);
                this.$store.commit('client/setSocial', {prop: 'ACFModalIsOpen', val: false})
            }else{
                alert('You must select a wordpress content field');
            }
        }catch(error){
            console.log('Error fetching wordpress import data', error);
        }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
@import './../../assets/scss/components/_social.scss';
.multiselect__tags{
    background: #3D4163;
    border: 1px solid #3D4163;
    margin: 20px 0;
}
.multiselect__input{
    background: #3D4163;
    border: 1px solid #3D4163 !important;
    color: white;
}
.multiselect__placeholder{
    color: white;
    font-size: 13px;
}
.multiselect__content-wrapper{
    background: #3D4163;
    border: 1px solid #3D4163;
}
.multiselect__element{
    margin-block: 10px !important;
}
.multiselect__element span{
    font-size: 13px;
    color: white;
}
.multiselect__tag-icon,
.multiselect__tag-icon:hover{
    color: white;
    background: rgb(58, 179, 220);
}
.multiselect__tag, 
.multiselect__option--highlight, 
.multiselect__option--highlight::after, 
.multiselect__option--selected,
.multiselect__option--selected::after{
    background: rgb(58, 179, 220);
    color: white;
};
</style>