<template>

    <div class="status-bar" :class="{isDraft : getSocial.post.approvedForPublishing === false && getSocial.post.hasbeenPublished === false, isDraft: getSocial.post.approvedForPublishing === true && getSocial.post.hasbeenPublished === false}">

        <button class="btn-primary btn-primary--outline calendar-btn" v-if="getSocial.currentPageSectionThatIsOpen !== 'overview' && getSocial.calendarIsOpen === ''" style="height: 40px; width: 119px; max-width: unset;" @click.prevent="this.$store.commit('client/setSocial', {prop: 'calendarIsOpen', val: true})"><i class="fa-regular fa-calendar"></i>Calendar</button>
        <span class="status-title">Status:</span>

        <div class="status-update">

            <span class="status-update-text" v-if="getSocial.post.approvedForPublishing === false && getSocial.post.hasbeenPublished === false"><span class="status-dot orange"></span>Draft</span> 
            <span  class="status-update-text" v-if="getSocial.post.approvedForPublishing === true && getSocial.post.hasbeenPublished === false"><span class="status-dot blue"></span>Scheduled</span> 
            <span  class="status-update-text" v-if="getSocial.post.approvedForPublishing === true && getSocial.post.hasbeenPublished === true"><span class="status-dot green"></span>Published</span> 

            <div class="status-social-icons">

                <a class="status-link" :class="{active : getSocial.post.scheduleTo.facebook || getCurrentNode.fieldGroups.format.fields.focus.value === 'Facebook' ? true : false, disable: getSocial.post.livePostLinks.facebook.url ? false : true }" target="_blank" :href="getSocial.post.livePostLinks.facebook.url"><i class="fa-brands fa-facebook-f"></i></a>
                <a class="status-link" :class="{active : getSocial.post.scheduleTo.instagram  || getCurrentNode.fieldGroups.format.fields.focus.value === 'Instagram' ? true : false, disable: getSocial.post.livePostLinks.instagram.url ? false : true  }" target="_blank" :href="getSocial.post.livePostLinks.instagram.url"><i class="fa-brands fa-instagram"></i></a>
                <a class="status-link" :class="{active : getSocial.post.scheduleTo.twitter  || getCurrentNode.fieldGroups.format.fields.focus.value === 'X' ? true : false, disable: getSocial.post.livePostLinks.twitter.url ? false : true  }" target="_blank" :href="getSocial.post.livePostLinks.twitter.url"><i class="fa-brands fa-x-twitter"></i></a>
                <a class="status-link" :class="{active : getSocial.post.scheduleTo.linkedin  || getCurrentNode.fieldGroups.format.fields.focus.value === 'Linkedin' ? true : false, disable: getSocial.post.livePostLinks.linkedin.url ? false : true  }" target="_blank" :href="getSocial.post.livePostLinks.linkedin.url"><i class="fa-brands fa-linkedin"></i></a>

            </div>
        </div>
    </div>

</template>

<script>

import {mapGetters} from "vuex"

export default {

    computed:{

        ...mapGetters("client", ["getClientSlug", "getCurrentNode", 'getSocial']),

    },
}

</script>

<style lang="scss">

@import "./../../../assets/scss/components/_social.scss";

</style>

