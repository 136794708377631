<template>
  <div class="image-upload">
    <p class="preview-text preview-text__image">Featured Image</p>
    <div @click.prevent='this.onOpenMediaModal()' class="image-upload__btn-container btn-primary btn-primary--outline" :class="{hide : getOpenBlogEditorAddNodeForm === false}" v-if="this.getNodeBlogContentFeaturedImage === ''">
      <i class="fa-duotone fa-upload"></i>
      Upload Featured Image
    </div>
    <div class="image-upload-wrap" v-if="this.getNodeBlogContentFeaturedImage">
      <i class="fa-solid fa-circle-xmark" @click.prevent="this.$store.commit('client/setNodeBlogContentFeaturedImage', '');"  v-if="getOpenBlogEditorAddNodeForm"></i>
      <img :src="this.getNodeBlogContentFeaturedImage" />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
export default {
  computed:{
    ...mapGetters("client", ["getOpenBlogEditorAddNodeForm", "getNodeBlogContentFeaturedImage"]),
    ...mapGetters("tools", ["getSelectedMediaMediaModal", "getAddMedia", "getMediaModalCurrentComponent"]),
  },
  props: ['valueProp'],
  methods: {
    onOpenMediaModal(){
      this.$store.commit("tools/setIsOpenMediaModal", true);
      this.$store.commit("tools/setMediaModalCurrentComponent", 'ImageUpload');
    },
  },
  watch:{
    getAddMedia(){
      //When image has been selected in the media modal run the below
      if(this.getSelectedMediaMediaModal.length > 0 && this.getMediaModalCurrentComponent === 'ImageUpload'){
        if(this.getSelectedMediaMediaModal[0].includes('mp4') === false){
          this.$store.commit('client/setNodeBlogContentFeaturedImage', this.getSelectedMediaMediaModal[0]);
        }
        this.$store.commit('tools/setAddMedia', false);
        this.$store.commit('tools/setSelectedMediaMediaModal', []);
      }
    },
  }
};
</script>





<style lang="scss" scoped>
	@import "../../../assets/scss/components/blog.scss";
</style>
