<template>
    <div class="google-modal" v-if="getSocial.googleModalIsOpen">
        <div class="google-modal__modal">
            <button class="btn-close social-modal__exit" @click.prevent="onClose"><i class="fas fa-times"></i>Close</button>


            <div class="google-page-select" v-if="steps === 0">
                <h2>Google Account</h2>
                <ul>
                    <li :id="item.id" :class="{'list-primary--selected' : this.isAccountID === item.id, 'list-primary list-primary--outline' : true}" v-for="item in getSocial.tokens.google.userLinkedCompanyAccounts" v-bind:key="item" @click.prevent="onSelectPage(item)">
                        {{ item.name }} - {{ item.id }}
                        <i v-if="this.isAccountID === item.id" class="fa-solid fa-circle-check"></i>
                    </li>
                </ul>
                <button class="btn-save btn-save--outline" :class="{'btn-save--disabled' : !this.isAccountID}" @click.prevent="toPropertyIdsSlide"><i class="fa-solid fa-arrow-right"></i>Next</button>
            </div>


            <div class="google-page-select" v-if="steps === 1">
                <h2>Property Id's (GA4)</h2>
                <ul v-if="loadingProperties === false && getSocial.tokens.google.userLinkedProperties.length > 0">
                    <li :id="item.id" :class="{'list-primary--selected' : this.isViewID === item.id, 'list-primary list-primary--outline' : true}" v-for="item in getSocial.tokens.google.userLinkedProperties" v-bind:key="item" @click.prevent="onSelectPage(item)">
                        {{ item.name }} - {{ item.id }}
                        <i v-if="this.isViewID === item.id" class="fa-solid fa-circle-check"></i>
                    </li>
                </ul>
                <ul v-if="loadingProperties">
                    <p>Loading</p>
                </ul>
                <ul v-if="loadingProperties === false && getSocial.tokens.google.userLinkedProperties.length === 0">
                    <p>There is no Property Id's. Make sure you have updated to GA4.</p>
                </ul>
                <div class="google-page-select__btns">
                    <button class="btn-close" @click.prevent="steps--"><i class="fa-solid fa-rotate-left"></i>Back</button>
                    <button :class="{'btn-save--disabled' : !this.isViewID}" class="btn-save" @click.prevent="onSaveSelectedPage"><i class="fa-solid fa-upload"></i>Save</button>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
import {mapGetters} from "vuex"
export default {
    data(){
        return{
            steps: 0,
            isAccountID: '',
            isViewID: '',
            loadingProperties: false
        }
    },
    computed:{
        ...mapGetters("client", ["getClientSlug", 'getSocial', 'getCurrentNode', 'getSocial']),
        ...mapGetters("auth", ["getAuthUsername"]),
    },
    watch:{
        'getSocial.tokens.google': {
        deep: true,
            handler() {
                if(this.steps === 0){
                    this.$store.dispatch('client/getGoogleAuthAccountIDs');
                }
            }
        },
        'getSocial.tokens.google.userLinkedProperties': {
        deep: true,
            handler() {
                this.loadingProperties = false;
            }
        },
        steps(step){
            if(step === 1){
                this.$store.dispatch('client/getGoogleAuthGA4PropertyIDs');
            }
        },
        'getSocial.tokens.google.userLinkedCompanyAccounts': function () {
            this.$store.commit('client/setHidePageLoader', true);
        },
    },
    mounted(){
        document.querySelector('body').classList.add('lock-body');
        if(this.getSocial.tokens.google.userLinkedCompanyAccounts && this.getSocial.tokens.google.userLinkedCompanyAccounts.length > 0){
            this.$store.commit('client/setHidePageLoader', true);
        }
    },
    methods: {
        toPropertyIdsSlide(){
            this.loadingProperties = true;
            this.steps++;
        },
        onClose(){
            document.querySelector('body').classList.remove('lock-body');
            this.$store.commit('client/setSocial', {prop: 'googleModalIsOpen', val: false})
        },
        onSelectPage(item){
            if(this.steps === 0){
                this.isAccountID = item.id;
                this.$store.commit('client/setSocial', {prop: 'tokens/google/accountID', val: item.id});
                console.log('google account ID set:', this.getSocial.tokens.google.accountID);
            }
            if(this.steps === 1){
                this.isViewID = item.id;
                this.$store.commit('client/setSocial', {prop: 'tokens/google/GA4PropertyID', val: item.id});
                console.log('google GA4PropertyID set:', this.getSocial.tokens.google.GA4PropertyID);
            }
        },
        onSaveSelectedPage(){
            if(this.getSocial.tokens.google.accountID && this.getSocial.tokens.google.GA4PropertyID){
                this.$store.commit('client/setSocial', {prop: 'tokens/google/userLinkedCompanyAccounts', val: []});
                this.$store.commit('client/setSocial', {prop: 'tokens/google/userLinkedViews', val: []});
                this.$store.commit('client/setSocial', {prop: 'googleModalIsOpen', val: false});
                this.$store.dispatch('client/updateSocialTokens');
                document.querySelector('body').classList.remove('lock-body');
                this.ranAlready = false;
                // Get the current URL
                let url = new URL(window.location.href);

                // Get the search parameters from the URL
                let params = new URLSearchParams(url.search);

                // Remove the 'googleModal' parameter
                params.delete('googleModal');

                params.delete('loader');

                // Add the 'google-auth-2' parameter
                params.append('google-next-stage', 'true');

                // Set the search parameters back to the URL
                url.search = params.toString();

                // Change the current URL without reloading
                history.pushState({}, '', url.toString());

                // // Reload the page
                // location.reload();

                this.$store.commit('client/setSocial', {prop: 'modalIsOpen', val: true});
                this.$store.commit('client/setBlogModalOpen', 'google');

            }else{
                alert('You have not selected items on step 1 or step 2');
            }
        }
    }
}
</script>
<style lang="scss">
@import "./../../../src/assets/scss/components/_socialModal.scss";
</style>