<template>
	<main class="main" id="main" v-bind:class="theme">
		<div class="section-report-bug" @click.prevent="reportProblem()"><button>Report bug<i class="fa-solid fa-bug" aria-hidden="true"></i></button></div>
		<PageLoader />
		<InfoModal />
		<MediaModal />
		<SocialModal />
		<Notification />
		<EditModal />
		<BlogModal />
		<ImportModal />
		<AIPopUp />
		<AIPopUpFields />
		<AIPopUpNodeData />
		<ACFDataPicker />
		<ReportProblemModal /> 
		<TagUsers /> 
		<MultiSelectNodeCategories />
		<GoogleModal v-if="getSocial.googleModalIsOpen" />
		<div class="contility">
			<img v-bind:src="contility" alt="the contility" title="The contility" />
		</div>
		<!-- end contility -->
		<div class="flex-container">
			<!-- GET SIDEBAR -->
			<div class="sidebar__wrapper" id="sidebarWrapper">
				<Sidebar @nodeSelectedInSearch="nodeSelectedInSearch" :clientSlug="clientSlug"></Sidebar>
				<!-- GET NODE OVERVIEW -->
				<router-view @nodeOverviewClosed="nodeOverviewClosed" name="nodeOverview"></router-view>
				<!-- GET ADD NODE FORM -->
				<router-view @nodeRemoved="removeNode" @treeSaved="treeSaved" name="addNodeForm"></router-view>
			</div>
			<div class="node-form-large-sections" 
			:class="{ 
				keywordPlannerOpen: getOpenKeywordPlanner, 
				blogOpen: getOpenBlogEditor, 
				blogOpenAddNodeForm: getOpenBlogEditorAddNodeForm, 
				commentsOpen: getOpenCommentPanel,
				'social--overview' :  getSocial.currentPageSectionThatIsOpen === 'overview' ? true : false, 
				'social--add-node-form' :  getSocial.currentPageSectionThatIsOpen === 'add-node-form' ? true : false 
				}"
			>
				<div>
					<KeywordPlanner v-if="getOpenKeywordPlanner" />
					<Blog  v-if="getOpenBlogEditor || getOpenBlogEditorAddNodeForm" />
					<Social v-if="getSocial.currentPageSectionThatIsOpen !== ''" />
				</div>
				<a class="close close-blog-overview" href="#" title="Close" @click.prevent="onCloseLargeSection()" v-if="getOpenBlogEditorAddNodeForm === false">
					<i class="fas fa-times"></i>
				</a>
			</div>
			<!-- end sidebar__wrapper -->
			<!-- MAIN CONTENT -->
			<div class="main-content" :class="{'content-list-open' : listClass }">
				<div class="container">
					<router-view :key="viewRefreshKey" :clientSlug="clientSlug" @editNode="selectNode" @collapseSidebar="collapseSidebar" v-slot="{Component}">
						<component ref="clientView" :is="Component" />
					</router-view>
				</div>
				<!-- end container -->
			</div>
			<!-- end main-content -->
		</div>
		<!-- end flex-container -->
	</main>
</template>

<script>
import Sidebar from "./../components/sidebar/Sidebar.vue"
import PageLoader from "./../components/loader/PageLoader.vue"
import KeywordPlanner from "./../components/section/KeywordPlanner.vue"
import Blog from "./../components/section/Blog.vue";
import Social from "../components/section/Social/Social.vue";
import InfoModal from "./../components/tools/InfoModal.vue";
import MediaModal from "../components/tools/media/MediaModal.vue";
import SocialModal from './../components/tools/SocialModal.vue';
import {mapGetters} from "vuex"
import Notification from './../components/tools/Notification.vue';
import EditModal from './../components/tools/EditModal.vue';
import BlogModal from './../components/tools/BlogModal.vue';
import ImportModal from './../components/tools/ImportModal.vue';
import GoogleModal from './../components/tools/GoogleModal.vue';
import AIPopUp from './../components/tools/AIPopUp.vue';
import AIPopUpNodeData from './../components/tools/AIPopUpNodeData.vue';
import AIPopUpFields from './../components/tools/AIPopUpFields.vue';
import ACFDataPicker from './../components/tools/ACFDataPicker.vue';
import ReportProblemModal from './../components/tools/ReportProblemModal.vue';
import TagUsers from './../components/tools/TagUsers.vue';
import MultiSelectNodeCategories from './../components/tools/MultiSelectNodeCategories.vue';
import html2canvas from 'html2canvas';

export default {
	data() {
		return {
			theme: "dashboard client has-sidebar blue-bg",
			contility: require("./../assets/img/contility.svg"),
			clientSlug: this.$route.params.client,
			viewRefreshKey: 0,
			listClass: ''
		}
	},
	async beforeMount(){
		try{
			await this.$store.dispatch('client/socialInit', this.clientSlug);
		}catch(error){
			console.log(error);
		}
	},
	mounted(){
		
		// Split the theme string into an array of classes
		let classes = this.theme.split(' ');

		// Remove the sidebar-open and sidebar-closed classes if they exist
		classes = classes.filter(c => c !== 'sidebar-open' && c !== 'sidebar-closed');

		// Append the new class based on the val parameter
		classes.push(this.getSidebarOpenState ? 'sidebar-open' : 'sidebar-closed');

		// Join the array back into a string and assign it to this.theme
		this.theme = classes.join(' ');

	},
	computed:{
		...mapGetters("client", ["getClientSlug", "getOpenKeywordPlanner", "getOpenBlogEditor", "getOpenBlogEditorAddNodeForm", "getOpenCommentPanel", "getSocial", "getListView"]),
		...mapGetters("tools", ["getInfoModal"]),
		...mapGetters("views", ["getSidebarOpenState"]),
		...mapGetters("auth", ["getAuthUsername"]),
	},
	watch:{
		'getSidebarOpenState' : function(val){
			//append to the class theme
			// Split the theme string into an array of classes
			let classes = this.theme.split(' ');

			// Remove the sidebar-open and sidebar-closed classes if they exist
			classes = classes.filter(c => c !== 'sidebar-open' && c !== 'sidebar-closed');

			// Append the new class based on the val parameter
			classes.push(val ? 'sidebar-open' : 'sidebar-closed');

			// Join the array back into a string and assign it to this.theme
			this.theme = classes.join(' ');
		},
		'getOpenBlogEditor': function(val){
			console.log('getOpenBlogEditor watcher', val)
		},
		'getOpenBlogEditorAddNodeForm': function(val){
			console.log('getOpenBlogEditorAddNodeForm watcher', val)
		},
		"getInfoModal.currentStep": function (newVal, oldVal) {
			console.log('the changed slide:', [newVal, oldVal])
		},
		"getCurrentNode": function (){
			const url = new URL(window.location.href);
			//url.searchParams.get('node-editor') === 'true'
			//if user was inside of the node, then open the node and go to the editor. 
			if(url.searchParams.get('node-editor') === 'true'){
				console.log('closing the sidebar contentmaster mounted in client');
				this.$store.commit('views/closeSidebar');
				this.$store.commit('client/setListView', true)
			}else{
				this.$store.commit('client/setListView', false);
			}

		},
		getListView(val){
			this.listClass = val;
			console.log('get list view!', val);
			// Split the theme string into an array of classes
			let classes = this.theme.split(' ');
			console.log('get list viewclasse1s!', classes);
			// Remove the sidebar-open and sidebar-closed classes if they exist
			classes = classes.filter(c => c !== 'list-view-open' && c !== 'list-view-close');
			console.log('get list viewclasses2!', classes);
			// Append the new class based on the val parameter
			classes.push(val ? 'list-view-open' : 'list-view-close');
			console.log('get list viewclasses3!', classes);
			// Join the array back into a string and assign it to this.theme
			this.theme = classes.join(' ');
			console.log('get list viewclasses4!', this.theme);
		},
	},
	methods: {
		onCloseLargeSection(){
			this.$store.commit("client/setOpenKeywordPlanner", false);
			this.$store.commit("client/setOpenBlogEditor", false);
			this.$store.commit("client/setOpenBlogEditorAddNodeForm", false);
			this.$store.commit("client/setOpenCommentPanel", false);
			this.$store.commit("client/setSocial", {prop: 'currentPageSectionThatIsOpen', val: ''});
		},
		nodeSelectedInSearch() {
			if(this.$refs){
				if(this.$refs.clientView && this.$refs.clientView.$.ctx && typeof this.$refs.clientView.$.ctx.centerOnNode === 'function'){
					this.$refs.clientView.$.ctx.centerOnNode()
				}
			}
		},
		async removeNode() {
			// console.log("node removed, registering emit in client.vue")
			await this.$store.dispatch("client/removeNode")
			++this.viewRefreshKey
		},
		async treeSaved() {
			// console.log("tree saved, registering emit in client.vue")
			await this.$store.dispatch("client/saveClientContentTree")
            ++this.viewRefreshKey
		},
		reRenderContentTree() {
			// console.log("re rendering tree!")
			++this.viewRefreshKey
		},
		checkSocialAuthCookie () {
			const cookies = document.cookie.split(';');
			for (const cookie of cookies) {
				if (cookie.trim().startsWith('social_auth=')) {
					return cookie.split('=')[1] === 'true';
				}
			}
			return false;
		},
		async reportProblem() {
            try {
                // this.onClose();
				document.querySelector('.main-content').classList.add('lock-section');
				document.querySelector('.section-report-bug').classList.add('lock-section');
				document.querySelector('.sidebar').classList.add('lock-section');
                const canvas = await html2canvas(document.querySelector('body'), { 
                    windowWidth: window.innerWidth, 
                    windowHeight: window.innerHeight,
                    x: window.pageXOffset,
                    y: window.pageYOffset
                });
                // Convert the canvas to Blob
                canvas.toBlob((blob) => {
                    // Save the screenshot to the store
                    this.$store.commit('client/setReportProblem', {prop: 'screenshot', val: blob});
                    this.$store.commit('client/setReportProblem', {prop: 'modalOpen', val: true});
                });
            } catch (error) {
                console.error('Error capturing screenshot:', error);
            }
        }
	},
	components: {
		Sidebar,
		PageLoader,
		KeywordPlanner,
		Blog,
		InfoModal,
		MediaModal,
		Social,
		SocialModal,
		Notification,
		EditModal,
		BlogModal,
		GoogleModal,
		AIPopUp,
		AIPopUpNodeData,
		AIPopUpFields,
		ImportModal,
		ACFDataPicker,
		ReportProblemModal,
		TagUsers,
		MultiSelectNodeCategories
	},
}
</script>
<style lang="scss">
@import "./src/assets/scss/dashboard.scss";
</style>
