<template>

    <div class="edit-modal" v-if="getSocial.editModalIsOpen">

        <div class="edit-modal__modal">

            <button class="btn-link social-modal__exit" @click.prevent="exit()"><i class="fas fa-times"></i>Close</button>

            <EditSocial />

        </div>

    </div>
    
</template>


<script>

import {mapGetters} from "vuex"
 import EditSocial from '../../components/section/Social/EditSocialModal.vue';

export default {
    computed:{

    ...mapGetters("client", ["getClientSlug", 'getSocial']),

    },
    components: {
		EditSocial
	},
    methods:{
        exit(){
            this.$store.commit('client/setSocial', {prop: 'editModalIsOpen', val: ''})
            this.$store.commit('client/setSocial', {prop: 'calendarIsOpen', val: ''})
        }
    }
}

</script>


<style lang="scss">

@import "./../../../src/assets/scss/components/_socialModal.scss";

</style>