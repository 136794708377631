<template>
    <div class="social-page-select">
        <div class="content">
            <h2>Linkedin Social Page Authentication</h2>
                <ul>
                    <li :id="item.companyId" 
                        :class="{'list-primary--selected' : selectedItem === item.companyId, 'list-primary list-primary--outline': true}" 
                        v-for="item in getSocial.tokens.linkedin.userLinkedCompanyData" 
                        v-bind:key="item" 
                        @click.prevent="onSelectPage(item)">
                        {{ item.companyName }}
                        <i v-if="selectedItem === item.companyId" class="fa-solid fa-circle-check"></i>
                    </li>

                </ul>
            <button class="btn-save" @click.prevent="onSaveSelectedPage"><i class="fa-solid fa-upload"></i>Save</button>
        </div>
    </div>    
</template>


<script>

import {mapGetters} from "vuex"

export default {
    data(){
        return{
            selectedItem: ''
        }
    },
    mounted(){
        document.querySelector('body').classList.add('lock-body');
        if(this.getSocial.tokens.linkedin.userLinkedCompanyData && this.getSocial.tokens.linkedin.userLinkedCompanyData.length > 0){
            this.$store.commit('client/setHidePageLoader', true);
        }
    },
    watch: {
        'getSocial.tokens.linkedin.userLinkedCompanyData': function () {
            this.$store.commit('client/setHidePageLoader', true);
        },
    },
    computed:{

        ...mapGetters("client", ["getClientSlug", 'getSocial', 'getCurrentNode']),
        ...mapGetters('auth', ['getAuthUsername'])
    },
    methods: {
        onSelectPage(item){
            console.log('=== item.id before ===', item['companyId']);
            this.selectedItem = item.companyId
            console.log('=== this.selectedItem ===', this.selectedItem);
        },
        async onSaveSelectedPage(){
            try{
                if(this.selectedItem){
                    this.$store.commit('client/setSocial', {prop: 'tokens/linkedin/companyID', val: this.selectedItem});
                    this.$store.commit('client/setSocial', {prop: 'accountSelectIsOpen', val: false});
                    await this.$store.dispatch('client/updateSocialTokens');
                    document.querySelector('body').classList.remove('lock-body');
                    this.$store.commit('client/setLoaders', {prop: 'hidePageLoader', val: true})
                    this.$store.commit('client/setLoaders', {prop: 'hideContentTreeLoader', val: true})
                    // Check and modify the URL
                    let url = new URL(window.location.href);
                    if(url.searchParams.get('loader') === 'true'){
                        url.searchParams.delete('loader');
                        window.history.replaceState({}, document.title, url.toString());
                    }
                }
            }catch(error){
                console.log('Linkedin onSaveSelectedPage catch error', error);
            }
        }
    }

}

</script>


<style lang="scss">

@import "./../../../../assets/scss/components/_social.scss";

</style>