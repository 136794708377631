<template>
    <div class="social-page-select">
        <div class="content">
            <h2>Meta Social Page Authentication</h2>
            <ul>
            <li :id="item.id" :class="{'list-primary--selected' : selectedItem.id === item.id, 'list-primary list-primary--outline': true}" v-for="item in getSocial.tokens.meta.allFacebookPages" v-bind:key="item" @click.prevent="onSelectPage(item)">
                {{ item.name }}
                <i v-if="selectedItem.id === item.id" class="fa-solid fa-circle-check"></i>
            </li>
            </ul>
            <button class="btn-save" @click.prevent="onSaveSelectedPage"><i class="fa-solid fa-upload"></i>Save</button>
        </div>
    </div>
</template>


<script>

import {mapGetters} from "vuex"
import axios from 'axios';

export default {
    
    //You must first get list of all facebook pages and then do api query to get the linked insta page that is connected to that fb page. its only way. 
    //We must also get the pageSlug to be used for the SEO fb blocks in anaylatics area.

    data(){
        return{
            selectedItem: '',
        }
    },
    computed:{

        ...mapGetters("client", ["getClientSlug", 'getSocial', 'getCurrentNode']),
        ...mapGetters('auth', ['getAuthUsername'])
    },
    mounted(){
        document.querySelector('body').classList.add('lock-body');
        if(this.getSocial.tokens.meta.allFacebookPages && this.getSocial.tokens.meta.allFacebookPages.length > 0){
            this.$store.commit('client/setHidePageLoader', true);
        }
    },
    watch: {
        'getSocial.tokens.meta.allFacebookPages': function () {
            this.$store.commit('client/setHidePageLoader', true);
        },
    },
    methods: {
        async onSelectPage(item){
            this.selectedItem = item;
            console.log('ON SELECT META PAGE SELECTED:', item);
        },
        async onSaveSelectedPage(){
            try{
                if(this.selectedItem){
                    const instagramResponse = await axios.get(`https://graph.facebook.com/v17.0/${this.selectedItem.id}?fields=instagram_business_account&access_token=${this.selectedItem.access_token}`);
                    const facebookResponse = await axios.get(`https://graph.facebook.com/${this.selectedItem.id}?fields=username&access_token=${this.selectedItem.access_token}`);
                    const fbPageLink =  facebookResponse.data.username;
                    
                    let instaPageID = '';
                    console.log('instagramResponse', instagramResponse.data);
                    if(instagramResponse.data.instagram_business_account){
                        instaPageID = instagramResponse.data.instagram_business_account.id;
                        }else{
                        instaPageID = '';
                        alert('This facebook page does not have a connected Instagram Account or you Did not allow permission to it during authentication.');
                    }

                    this.$store.commit('client/setSocial', {prop: 'tokens/meta/facebookPageID', val: this.selectedItem.id});
                    this.$store.commit('client/setSocial', {prop: 'tokens/meta/pageAccessToken', val: this.selectedItem.access_token});
                    this.$store.commit('client/setSocial', {prop: 'tokens/meta/instaPageID', val: instaPageID});
                    this.$store.commit('client/setSocial', {prop: 'tokens/meta/facebookSlug', val: fbPageLink});

                    this.$store.commit('client/setSocial', {prop: 'accountSelectIsOpen', val: false});

                    await this.$store.dispatch('client/updateSocialTokens');
                    await this.$store.dispatch('client/getSocialAccountStatus');

                    document.querySelector('body').classList.remove('lock-body');
                    this.$store.commit('client/setLoaders', {prop: 'hidePageLoader', val: true})
			        this.$store.commit('client/setLoaders', {prop: 'hideContentTreeLoader', val: true})

                    // Check and modify the URL
                    let url = new URL(window.location.href);
                    if(url.searchParams.get('loader') === 'true'){
                        url.searchParams.delete('loader');
                        window.history.replaceState({}, document.title, url.toString());
                    }

                }   
            }catch(error){
                console.log('onSaveSelectedPage meta error', error);
            }
        }
    }

}

</script>


<style lang="scss">

@import "./../../../../assets/scss/components/_social.scss";

</style>