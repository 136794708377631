<template>
    <div v-if="this.getTaggedUsers.modalIsOpen" class="ai-pop-up-wrap">
        <input type="text" class="hidden" style="height: 0" id="focus-on-me" >
        <div class="ai-generate-fields" v-if="loader === false">
            <label for="voice">Tag users</label>
            <div v-if="this.getTaggedUsers.allUsers && this.getTaggedUsers.allUsers.length > 0">
                <multiselect v-model="value" tag-placeholder="Add this as new tagged user" placeholder="Search or add a tagged user" label="name" track-by="code" :options="options" :multiple="true" :taggable="true" @tag="addTag"></multiselect>
            </div>
            <div v-else>
                <p>Waiting for users.</p>
            </div>
            <div class="btn-wrap">
              <button class="btn-save" @click.prevent="onSubmit()"><i class="fa-solid fa-plus"></i>Add</button>
              <button class="btn-close" @click.prevent="onClose()"><i class="fa-sharp fa-solid fa-xmark"></i>Close</button>
            </div>
        </div>
        <div v-if="loader">
            <h2 style="color: white;">Loading...</h2>
        </div>
    </div>
</template>


<script>
import { mapGetters } from 'vuex';
import multiselect from 'vue-multiselect';

export default {
  data(){
    return {
      open: false,
      loader: false,
      value: [],
      options: [],
    }
  },
  components: { multiselect },
  computed: {
    ...mapGetters('client', ['getSocial', 'getTaggedUsers']),
  },
  mounted(){
    this.getTaggedUsers.allUsers.length > 0 ? this.setOptions(this.getTaggedUsers.allUsers) :  this.getUsers();
  },
  watch:{
    'getTaggedUsers.allUsers' : function (users){
        this.setOptions(users);
    },
  },
  methods: {
    async getUsers() {
        try {
            const usersData = await this.$store.dispatch("user/getAllUsers");
            const users = usersData.map(user => ({name: user.fullName, code: user.username}));
            this.$store.commit('client/setTaggedUsers', {prop: 'allUsers', val: users});
        } catch (error) {
            console.log("TagUsers getUsers catch error", error)
        }
    },
    addTag (newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.options.push(tag)
      this.value.push(tag)
    },
    setOptions(data){
        this.options.length === 0 && data.length > 0 ? this.options = data : this.options = [];
    },
    onClose(){
        this.$store.commit('client/setTaggedUsers', {prop: 'modalIsOpen', val: false});
        this.$store.commit('client/setTaggedUsers', {prop: 'selected', val: []})
        this.$store.commit('client/setTaggedUsers', {prop: 'selectedString', val: ''})
        this.$store.commit('client/setTaggedUsers', {prop: 'lastComponentClicked', val: false})
    },
    onSubmit(){
        if(this.value && this.value.length > 0){
            const usernames = this.value.map(user => `@${user.code} `);
            const string = usernames.join(' ');
            this.$store.commit('client/setTaggedUsers', {prop: 'selectedString', val: string});
            this.$store.commit('client/setTaggedUsers', {prop: 'modalIsOpen', val: false});
            this.value = [];
        }else{
            alert('You must select a user');
        }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
@import './../../assets/scss/components/_social.scss';
.multiselect__tags{
    background: #3D4163;
    border: 1px solid #3D4163;
    margin: 20px 0;
}
.multiselect__input{
    background: #3D4163;
    border: 1px solid #3D4163 !important;
    color: white;
}
.multiselect__placeholder{
    color: white;
    font-size: 13px;
}
.multiselect__content-wrapper{
    background: #3D4163;
    border: 1px solid #3D4163;
}
.multiselect__element{
    margin-block: 10px !important;
}
.multiselect__element span{
    font-size: 13px;
    color: white;
}
.multiselect__tag-icon,
.multiselect__tag-icon:hover{
    color: white;
    background: rgb(58, 179, 220);
}
.multiselect__tag, 
.multiselect__option--highlight, 
.multiselect__option--highlight::after, 
.multiselect__option--selected,
.multiselect__option--selected::after{
    background: rgb(58, 179, 220);
    color: white;
};
</style>