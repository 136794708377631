<template>

    <div class="social-select" v-if="getSocial.post.hasbeenPublished === false">

        <span class="social-select-title">Schedule to</span>

        <ul class="social-select__icons-list">

            <li class="social-select__list-item" @click.prevent="onAccountSelect('facebook')" :class="{'not-active' : getSocial.post.scheduleTo.facebook === false && this.getCurrentNode.fieldGroups.format.fields.focus.value !== 'Facebook'}">
                <i class="fa-brands fa-facebook-f"></i>
            </li>

            <li class="social-select__list-item" @click.prevent="onAccountSelect('instagram')" :class="{'not-active' : getSocial.post.scheduleTo.instagram === false && this.getCurrentNode.fieldGroups.format.fields.focus.value !== 'Instagram'}">
                <i class="fa-brands fa-instagram"></i>
            </li>

            <li class="social-select__list-item" @click.prevent="onAccountSelect('twitter')" :class="{'not-active' : getSocial.post.scheduleTo.twitter === false && this.getCurrentNode.fieldGroups.format.fields.focus.value !== 'X'}">
                <i class="fa-brands fa-x-twitter"></i>
            </li>

            <li class="social-select__list-item" @click.prevent="onAccountSelect('linkedin')" :class="{'not-active' : getSocial.post.scheduleTo.linkedin === false && this.getCurrentNode.fieldGroups.format.fields.focus.value !== 'Linkedin'}">
                <i class="fa-brands fa-linkedin"></i>
            </li>

        </ul>
        
    </div>
    
</template>


<script>

import {mapGetters} from "vuex"

export default {

    computed:{

        ...mapGetters("client", ["getClientSlug", 'getSocial', 'getCurrentNode']),

    },
    watch: {
        'getSocial.socialAccountInformation.twitter.error': function(newVal) {
            if(newVal) {
                this.isTwitterValid(newVal);
            }
        },
        'getSocial.socialAccountInformation.meta.fbPageTokenStatus.is_valid': function(newVal) {
            if(newVal) {
                this.isMetaValid(newVal);
            }
        },
        'getSocial.socialAccountInformation.meta.fbUserTokenStatus.is_valid': function(newVal) {
            if(newVal) {
                this.isMetaValid(newVal);
            }
        }
    },
    mounted(){
        this.isTwitterValid(this.getSocial.socialAccountInformation.twitter.error);
        this.isMetaValid(this.getSocial.socialAccountInformation.meta.fbPageTokenStatus.is_valid);
        this.isMetaValid(this.getSocial.socialAccountInformation.meta.fbUserTokenStatus.is_valid);
    },
    methods : {
        isTwitterValid(newVal){
            if(this.getCurrentNode.fieldGroups.format.fields.focus.value !== 'X'){
                if(this.getSocial.post.scheduleTo.twitter){
                    alert(`X platform error: ${newVal.title}`);
                }
                this.$store.commit('client/setSocial', {prop: `post/scheduleTo/twitter`, val: false});
            }
        },
        isMetaValid(newVal){
            if(newVal === false){
                if(this.getCurrentNode.fieldGroups.format.fields.focus.value !== 'facebook'){
                    if(this.getSocial.post.scheduleTo.facebook){
                        alert(`Facebook platform error: Problem with tokens`);
                    }
                    this.$store.commit('client/setSocial', {prop: `post/scheduleTo/facebook`, val: false});
                }
                if(this.getCurrentNode.fieldGroups.format.fields.focus.value !== 'Instagram'){
                    if(this.getSocial.post.scheduleTo.instagram){
                        alert(`Instagram platform error: Problem with tokens`);
                    }
                    this.$store.commit('client/setSocial', {prop: `post/scheduleTo/instagram`, val: false});
                }
            }
        },
        async onAccountSelect(platform){
            try{
                let allowSelect = false;
                if(platform === 'facebook'){
                    if(this.getCurrentNode.fieldGroups.format.fields.focus.value === 'Facebook'){
                        alert('This node focus is set to Facebook. You can change this in the Format tab');
                    }else{
                        if(this.getSocial.socialAccountInformation.meta.fbPageTokenStatus.is_valid && this.getSocial.socialAccountInformation.meta.fbUserTokenStatus.is_valid && this.getSocial.socialAccountInformation.meta.fbPageName){
                            allowSelect = true;
                        }else{
                            allowSelect = false;
                            alert('Social platform needs to be connected');
                        }
                    }
                }
                if(platform === 'instagram'){
                    if(this.getCurrentNode.fieldGroups.format.fields.focus.value === 'instagram'){
                        alert('This node focus is set to instagram. You can change this in the Format tab');
                    }else{
                        if(this.getSocial.socialAccountInformation.meta.fbPageTokenStatus.is_valid && this.getSocial.socialAccountInformation.meta.fbUserTokenStatus.is_valid && this.getSocial.socialAccountInformation.meta.instaPageName){
                        allowSelect = true;
                        }else{
                            allowSelect = false;
                            alert('Social platform needs to be connected');
                        }
                    }
                }
                if(platform === 'twitter'){
                    if(this.getCurrentNode.fieldGroups.format.fields.focus.value === 'X'){
                        alert('This node focus is set to X. You can change this in the Format tab');
                    }else{
                        if(this.getSocial.socialAccountInformation.twitter.name && this.getSocial.socialAccountInformation.twitter.error == false){
                            allowSelect = true;
                        }else{
                            allowSelect = false;
                            if(this.getSocial.socialAccountInformation.twitter.error){
                                alert(`X platform error: ${this.getSocial.socialAccountInformation.twitter.error.title}`)
                            }else{
                                alert('Social platform needs to be connected');
                            }
                        }   
                    }
                }
                if(platform === 'linkedin'){
                    if(this.getCurrentNode.fieldGroups.format.fields.focus.value === 'linkedin'){
                        alert('This node focus is set to Linkedin. You can change this in the Format tab');
                    }else{
                        if(this.getSocial.socialAccountInformation.linkedin.name && this.getSocial.socialAccountInformation.linkedin.pageName){
                            allowSelect = true;
                        }else{
                            allowSelect = false;
                            alert('Social platform needs to be connected');
                        }   
                    }
                }
                if(allowSelect){
                    this.$store.commit('client/setSocial', {prop: `post/scheduleTo/${platform}`, val: !this.getSocial.post.scheduleTo[platform]});
                }
            }catch(error){
                console.log('onAccountSelect error', error);
            }

        },
    }
}

</script>


<style lang="scss">

@import "./../../../assets/scss/components/_social.scss";

</style>