<template>
  <div class="calendar-wrap">
    <div class="calendar-header-nav">
                    <div class="calendar-legend">
                        <span class="legend-title">Legend:</span>
                        <ul lass="legend-list">
                            <li class="legend-list-item">
                                <span class="legend-list-dot orange"></span>
                                <span class="legend-list-text">Draft</span>
                            </li>
                            <li class="legend-list-item">
                                <span class="legend-list-dot blue"></span>
                                <span class="legend-list-text">Scheduled</span>
                            </li>
                            <li class="legend-list-item">
                                <span class="legend-list-dot green"></span>
                                <span class="legend-list-text">Published</span>
                            </li>
                        </ul>
                    </div>
                    <button class="btn-close social-modal__exit" @click.prevent="this.$store.commit('client/setSocial', {prop: 'calendarIsOpen', val: ''})"><i class="fa-sharp fa-solid fa-xmark"></i>Close</button>
                </div>
    <Qalendar
      :selected-date="new Date()"
      :events="posts"
      :config="config"
      @event-was-clicked="onPostClick"
    />
  </div>
</template>

<script>

//https://tomosterlund.github.io/qalendar/guide.html (this is the calender being used)
import { Qalendar } from "qalendar";
import {mapGetters} from "vuex"

export default {
  watch:{
    'hasFormatAndCalendarIsOpen': async function(val){
      try{
        if(val){
          console.log('starting calendar');
          await this.init();
        }else{
          console.log('resetting calendar');
          this.$store.commit('client/setSocial', {prop: 'calendar', val: []});
        }
      }catch(error){
        console.log('hasFormatAndCalendarIsOpen error in watch:', error);
      }
    },
  },
  async mounted(){
    try{
      console.log('starting calendar');
          await this.init();
      }catch(error){
        console.log('hasFormatAndCalendarIsOpen error in watch:', error);
      }
  },
  components: {
    Qalendar,
  },
  computed:{

    ...mapGetters("client", ["getClientSlug", 'getSocial', 'getCurrentNode']),
    hasFormatAndCalendarIsOpen(){
      return this.getCurrentNode.fieldGroups.format.fields.format.value && this.getSocial.calendarIsOpen;
    },
    posts(){
      
      let posts = [];

      if(this.getSocial.calendar.length > 0){
        
        if(this.getCurrentNode.fieldGroups.format.fields.format.value === 'Blog'){
          console.log('psts is blog');
          posts = this.getSocial.calendar.map(post => {
            let location = ['Wordpress'];
            let color = '';
            if(post.approvedForPublishing === false){
              //draft
              color = 'red';
            }
            if(post.approvedForPublishing === true && post.hasbeenPublished === false){
              //scheduled
              color = 'blue';
            }
            if(post.hasbeenPublished === true){
              //publushed
              color = 'green';
            }
            let date = post.date ? new Date(post.date) : new Date();
            let formattedDate = date.toISOString().slice(0, 16).replace('T', ' ');
            const obj = {
                  title: post.title,
                  with: post.clientSlug,
                  time: { start: formattedDate, end: formattedDate },
                  color: color,
                  isEditable: false,
                  id: post.nodeID,
                  location: location,
                  description: post.blogHTML,
                };
                return obj;
          });
      }else{
        console.log('psts is soicial');
              posts = this.getSocial.calendar.map(post => {

                let location = [];
                let color = '';

                if(post.scheduleTo.facebook){
                  location.push('Facebook');
                }
                if(post.scheduleTo.instagram){
                  location.push('Instagram');
                }
                if(post.scheduleTo.twitter){
                  location.push('X');
                }
                if(post.scheduleTo.linkedin){
                  location.push('Linkedin');
                }
                location = location.join(',')

                if(post.approvedForPublishing === false){
                  //draft
                  color = 'red';
                }
                if(post.approvedForPublishing === true && post.hasbeenPublished === false){
                  //scheduled
                  color = 'blue';
                }
                if(post.hasbeenPublished === true){
                  //publushed
                  color = 'green';
                }
                const domainURL = process.env.DOMAIN_URL;
                let date = new Date(post.publishDate);
                let formattedDate = date.toISOString().slice(0, 16).replace('T', ' ');
                let image;
                if(post.media.length > 0){
                  image = post.media[0];
                }else{
                  image = `${domainURL}/misc/calendar-placeholder.png`;
                }
                const obj = {
                  title: image,
                  with: post.clientSlug,
                  time: { start: formattedDate, end: formattedDate },
                  color: color,
                  isEditable: false,
                  id: post.nodeID,
                  location: location,
                  description: post.caption,
                };
                return obj;
            })
        }

      }
      return posts;
    }

  },
  data() {
    return {
      hasFormat: false,
      config: {
        defaultMode: "month",
        style: {
          colorSchemes: {
            meetings: {
              color: "#fff",
              backgroundColor: "#131313",
            },
            sports: {
              color: "#fff",
              backgroundColor: "#ff4081",
            },
          },
        },
        dayIntervals: {
          length: 30, // Length in minutes of each interval. Accepts values 15, 30 and 60 (the latter is the default)
          height: 150, // The height of each interval
          displayClickableInterval: true, // Needs to be set explicitly to true, if you want to display clickable intervals
        },
      },
    };
  },
  methods: {
    async init(){
      try{
        console.log('calendar is before', [this.getCurrentNode.fieldGroups.format.fields.format.value]);
          if(this.getCurrentNode.fieldGroups.format.fields.format.value === 'Blog'){
            console.log('getting blog posts')
            await this.$store.dispatch('client/getBlogPosts');    
          }else{
            console.log('getting social posts');
            await this.$store.dispatch('client/getSocialPosts');
                      //.calendar-week__event'
              this.updateImages('.calendar-month__event', '.calendar-month__event-title');

              document.querySelector('.calendar-root-wrapper').addEventListener('click', () => {
                this.updateImages('.calendar-month__event', '.calendar-month__event-title');
                this.updateImages('.calendar-week__event', '.calendar-week__event-row.is-title');
                this.updateWeekColor();
                this.updateTime();
              })
          }
      }catch(error){
        console.log('social calendar error', error);
      }
    },
    onPostClick(data) {
      console.log('post clicked:', data.clickedEvent.id);

      // Get the current URL.
      const currentURL = window.location.href;

      // Set the value of the 'node' parameter to 'data.clickedEvent.id'.
      const updatedURL = new URL(currentURL);
      updatedURL.searchParams.set('node', data.clickedEvent.id);

      // Navigate to the updated URL.
      window.location.href = updatedURL.toString();
    },
    updateWeekColor() {
      if(document.querySelector('.calendar-root.mode-is-week')){
          let currentDay = new Date().getDay();
          currentDay = (currentDay === 0) ? 7 : currentDay;
          let calendarDayElement = document.querySelector(`.calendar-week__day:nth-of-type(${currentDay})`);
          if (calendarDayElement) {
              calendarDayElement.classList.add('active-day');
          }
      }
      if(document.querySelector('.calendar-root.mode-is-day')){
          let currentTime = new Date().getHours();
          currentTime = (currentTime === 0) ? 24 : currentTime;
          let calendarDayElement = document.querySelector(`.day-timeline__hour:nth-of-type(${currentTime})`);
          if (calendarDayElement) {
              calendarDayElement.classList.add('active-time');
          }
      }
    },
    updateTime(){
      const times = document.querySelectorAll('.calendar-week__event-row.is-time');
      if(times){
        times.forEach(time => {
          let oldText = time.querySelector('span').innerHTML;
          let newText = oldText.split(' - ')[0];
          time.querySelector('span').innerHTML = newText;
        })
      }
    },
    updateImages(location, title){
      if(document.querySelectorAll(location).length > 0){
          document.querySelectorAll(location).forEach(post => {
          const el = post.querySelector(title);
          if(el){
            const url = el.innerText;
            const img = document.createElement('img');
            img.src = url;
            img.style.width = '35px';
            el.replaceWith(img);
            post.addEventListener('click', () => {
              let interval = setInterval(() => {
                let element = document.querySelector('.event-flyout__row.is-title');
                if (element) {
                  element.innerHTML = `<img style="width: 70px; margin-bottom: 10px;" src="${url}">`;
                  clearInterval(interval);
                }
              }, 100);
            })
          }
        });
      }
    }
  }
};
</script>

<style>
    @import "../../../../node_modules/qalendar/dist/style.css";
    
    /* calendar styling header*/
    .calendar-legend{
      display: flex;
    }
    .legend-title{
      margin-right: 20px;
      font-weight: bold;
    }
    .calendar-legend ul{
      display: flex;
    }
    .calendar-legend li{
      display: flex;
      align-items: center;
    }
    .legend-list-item{
      display: flex;
      align-items: center;
      margin-right: 10px;
    }
    .legend-list-dot{
      width: 10px;
      height: 10px;
      display: block;
      border-radius: 50%;
      margin-right: 7px;
    }
    .legend-list-dot.orange{
      background: #FF8344;
    }
    .legend-list-dot.blue{
      background: #2ABBEE;
    }
    .legend-list-dot.green{
      background: #18B28C;
    }
    .calendar-header-nav{
      display: flex;
      align-items: center;
    }
    /* calendar styling header*/

    /* fix for the time in the week*/
    .day-timeline__hour-text{
      display: flex !important;
    }
    /* fix for the time in the week*/

        /* change color of border*/
    .calendar-week__day, .day-timeline__hour, .week-timeline{
      border-color: rgba(62, 66, 100, 0.5) !important;
    }
     /* change color of border*/

    /* hide unessary content on week view*/
    .calendar-week__event-row.is-location,
    .calendar-week__event-row.is-with,
    .calendar-week__event-row.is-description,
    .calendar-week__event-icon{
      display: none !important;
    }
    .calendar-week__event-info-wrapper{
      display: flex;
      height: 45px !important;
    }
    .calendar-week__event-info-wrapper img{
      border-radius: 8px;
      width: 30px !important;
      height: 30px !important;
      object-fit: cover;
    }
    .calendar-week__event-row.is-time{
      align-items: center !important;
      margin-bottom: 0 !important;
      margin-left: 5px;
    }
    .calendar-week__event-row.is-time span{
      font-size: 10px !important;
    }
   /* hide unessary content on week view */

   .calendar-header__mode-options{
      background: #3E4264 !important;
      border-radius: 6px !important;
      overflow: hidden;
   }
   .calendar-header__mode-option:hover{
    background: #1A183A !important;
   }
   .calendar-root .week-timeline .week-timeline__date{
    background-color: transparent !important;
   }

     /* overides*/
     .calendar-month__weekday.is-today,
    .calendar-week__day.active-day, 
    .day-timeline__hour.active-time, 
    .calendar-month__week .calendar-month__weekday.is-today{
      border-color: $btn-primary !important;
      border: 1px solid $btn-primary !important;
      border-radius: 8px !important;
    };
    .calendar-month__weekday.is-today .calendar-month__day-date{
      background-color: transparent !important;
    }
    .calendar-header{
      background: #3E4264;
      margin-block: 20px;
      margin-top: 0;
    }
    .calendar-month__day-date{
      background-color: transparent !important;
    }
    .calendar-month__event img{
      overflow: hidden;
      border-radius: 7px;
    }
    .calendar-month__event:hover{
      background: #242750 !important
    }
    .calendar-week__day .calendar-week__day-interval.has-border{
      border-color: rgba(62, 66, 100, 0.5) !important;
    }
    /* overides*/

</style>

