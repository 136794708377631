<template>
    <div class="media-modal" v-if="getMediaModal.isOpen">
        <div class="media-modal__modal">
            <button class="btn-close media-modal__exit" @click.prevent='this.$store.commit("tools/setIsOpenMediaModal", false)'><i class="fa-sharp fa-solid fa-xmark"></i>Close</button>
            <DropFile />
            <MediaList :key="getReloadMediaList" />
            <div class="media-modal__footer">
                <button v-if="getSelectedMediaMediaModal.length > 0" class="btn-close media-modal__delete" @click.prevent='this.$store.dispatch("tools/deleteMedia")'><i class="fa-solid fa-trash"></i>Delete</button>
                <button v-if="getSelectedMediaMediaModal.length > 0" class="btn-save btn-save--outline media-modal__done" @click.prevent='this.$store.commit("tools/setAddMedia", true)'><i class="fa-duotone fa-circle-check"></i>Add</button>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters} from "vuex"
import DropFile from "./DropFile.vue";
import MediaList from "./MediaList.vue";
export default {
    computed:{
        ...mapGetters("tools", ["getMediaModal", "getReloadMediaList", "getSelectedMediaMediaModal"]),
        ...mapGetters("client", ["getClientSlug", "getMediaList","getClientData"]),
    },
    watch:{
        'getMediaModal.isOpen': function (){
            this.$store.commit('tools/setClientSlug', this.getClientSlug)
        }
    },
    mounted(){
        this.$store.commit('tools/setClientSlug', this.getClientSlug)
    },
    components:{
        DropFile,
        MediaList
    }
}
</script>
<style lang="scss" scoped>
@import "./../../../assets/scss/components/_mediaModal.scss";
</style>