<template>
  <div class="caption-and-save-wrap">
      <div class="caption-text">
      <textarea
        id="text-area"
        :value="getSocial.post.caption"
        @input="onInput"
      ></textarea>
      <button class="ai-generate-icon" @click.prevent="this.$store.commit('client/setAIPopUpFields', {prop: 'modals/social', val: true})" ><i class="fa-solid fa-wand-magic-sparkles"></i></button>
      <button id="toggle-button">😀</button>
      <emoji-picker id="emoji-picker" hidden></emoji-picker>
    </div>
    <div class="save-post-wrap">
      <SavePost v-if="getSocial.currentPageSectionThatIsOpen === 'overview' ? false : true" />
    </div>
  </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  import 'emoji-picker-element';
  import SavePost from './SavePost.vue';
  
  export default {
    components: {
      SavePost,
    },
    computed: {
      ...mapGetters('client', ['getClientSlug', 'getSocial']),
    },
    methods: {
      onInput(event) {
        this.$store.commit('client/setSocial', {
          prop: 'post/caption',
          val: event.target.value,
        });
      },
    },
    mounted() {
      console.log('caption mounted before');
      // Get the elements
      const textArea = document.getElementById('text-area');
      const toggleButton = document.getElementById('toggle-button');
      const emojiPicker = document.getElementById('emoji-picker');
  
      // Toggle the emoji picker visibility
      toggleButton.addEventListener('click', () => {
        emojiPicker.hidden = !emojiPicker.hidden;
      });
  
      // Close the emoji picker when clicking outside of it
      document.addEventListener('click', event => {
        if (
          !emojiPicker.hidden &&
          !emojiPicker.contains(event.target) &&
          event.target !== toggleButton
        ) {
          emojiPicker.hidden = true;
        }
      });
  
      // Insert the selected emoji into the text area and close the emoji picker
      emojiPicker.addEventListener('emoji-click', event => {
        const emoji = event.detail.unicode;
        const start = textArea.selectionStart;
        const end = textArea.selectionEnd;
        const text = textArea.value;
        const before = text.substring(0, start);
        const after = text.substring(end, text.length);
        textArea.value = before + emoji + after;
        this.$store.commit('client/setSocial', { prop: 'post/caption', val: textArea.value });
        textArea.focus();
        textArea.selectionStart = start + emoji.length;
        textArea.selectionEnd = start + emoji.length;
        emojiPicker.hidden = true;
      });
      console.log('caption mounted after');
    },
  };
  </script>
  
  <style>
  @import './../../../assets/scss/components/_social.scss';
  </style>
  
  
  
