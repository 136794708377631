<template>

    <button class="btn-save btn-save--outline" style="width: 141px;" @click.prevent="saveDraft"  v-if="getSocial.post.hasbeenPublished === false && getSocial.calendarIsOpen === ''"><i class="fa-solid fa-floppy-disk"></i>Save Draft</button>
</template>

<script>

import {mapGetters} from "vuex"

export default {
    
    computed:{

        ...mapGetters("client", ["getClientSlug", 'getSocial']),

    },
    methods: {
        async saveDraft(){
            try{
                const isNodePosting = await this.$store.dispatch("client/isNodePosting");
                if(isNodePosting.status === false){
                        this.$store.commit('client/setSocial', {prop: 'post/approvedForPublishing', val: false});
                        this.$store.dispatch('client/updateSocialPost')
                }else{
                    alert(isNodePosting.error)
                }
            }catch(error){
                console.log('error:', error);
            }
        }
    }
}

</script>


<style lang="scss">

@import "./../../../assets/scss/components/_social.scss";

</style>

